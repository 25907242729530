import { useState } from "react";
import {
  GetOccupationsDocument,
  OccupationInput,
  useGetOccupationsQuery,
  useUpdateOccupationAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useOccupations() {
  const [additionMode, setAdditionMode] = useState(false);
  const { loading, data, refetch } = useGetOccupationsQuery();

  const [updateOccupationAsAdmin] = useUpdateOccupationAsAdminMutation();

  function onAddClick() {
    setAdditionMode(true);
  }

  function onAdditionCancel() {
    setAdditionMode(false);
  }

  function onSubmit({
    id,
    name,
    iconUrl,
    order,
  }: {
    id: string | null;
    name: string;
    iconUrl: string;
    order: number;
  }) {
    const occupationInput: OccupationInput = {
      id: id || null,
      name,
      iconUrl: iconUrl || null,
      order,
    };

    updateOccupationAsAdmin({
      variables: {
        occupationInput,
      },
      refetchQueries: !id
        ? () => [
            {
              query: GetOccupationsDocument,
            },
          ]
        : undefined,
    });
  }

  return {
    models: {
      additionMode,
      loading,
      data: data?.getOccupations,
    },

    operations: {
      onSubmit,
      onAddClick,
      onAdditionCancel,
    },
  };
}

export default useOccupations;
