import React from "react";
import styled, { ThemedComponentProps } from "styled-components";

interface AvatarProps {
  diameter?: number;
  uri?: string | null;
}

function Avatar({ diameter, uri }: AvatarProps) {
  return (
    <Wrapper diameter={diameter}>
      {uri && <Image src={uri} diameter={diameter} />}
    </Wrapper>
  );
}

interface WrapperProps extends ThemedComponentProps {
  diameter?: number;
}

const Wrapper = styled.div<any>(({ theme, diameter = 100 }: WrapperProps) => ({
  width: diameter,
  height: diameter,
  borderRadius: diameter,
  backgroundColor: theme.color.black,
}));

const Image = styled.img<any>(({ theme, diameter }: WrapperProps) => ({
  width: diameter,
  height: diameter,
  borderRadius: diameter,
}));

export default Avatar;
