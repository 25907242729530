import { useState } from "react";

function useMoimListCard() {
  const [showModal, setShowModal] = useState(false);

  function toggleShowModal(show: boolean) {
    setShowModal(show);
  }

  return {
    models: {
      showModal,
    },
    operations: {
      toggleShowModal,
    },
  };
}

export default useMoimListCard;
