import { useState } from "react";
import { useGetHostsByVerificationQuery } from "../../../../lib/apollo/graphql/generated";
import { HOST_LIST_TAB } from "../../../../utilites/constants";

const HOST_VERIFIED_MAP = {
  [HOST_LIST_TAB.ALL]: null,
  [HOST_LIST_TAB.REQUESTED]: false,
  [HOST_LIST_TAB.VERIFIED]: true,
};

function useHostListContainer() {
  const [tab, setTab] = useState(HOST_LIST_TAB.ALL);

  const { data, loading, fetchMore, refetch } = useGetHostsByVerificationQuery({
    variables: {
      verified: HOST_VERIFIED_MAP[tab],
      first: 10,
    },
  });

  function toggleTab(tab: string) {
    setTab(tab);
  }

  return {
    models: {
      loading,
      data: data?.getHostsByVerification?.edges,
      tab,
    },
    operations: {
      toggleTab,
    },
  };
}

export default useHostListContainer;
