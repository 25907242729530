import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useUpdateUserContainer from "./UpdateUserContainer.hook";
import { Col, Input, Row } from "../../../common/styles/commonStyles";

function UpdateUserContainer() {
  const {
    models: { state, loading, updateLoading },
    operations: { onInputChange, onSubmit },
  } = useUpdateUserContainer();

  if (loading) return <div />;

  return (
    <>
      <ButtonWrapper>
        <button
          style={{ fontSize: "inherit" }}
          disabled={
            updateLoading || !(state.name && state.username && state.email)
          }
          onClick={onSubmit}
        >
          업데이트!
        </button>
      </ButtonWrapper>
      <Wrapper>
        {Object.keys(state).map((key) => (
          <InputWrapper key={key}>
            <Label>{key}</Label>
            <Input
              disabled={key === "id" || (key === "email" && !!state.id)}
              value={state[key]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, key)
              }
            />
          </InputWrapper>
        ))}
      </Wrapper>
    </>
  );
}

const ButtonWrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  justifyContent: "flex-end",
  margin: theme.spacing.middle,
  ...theme.font.head4,
}));

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridAutoRows: "auto",
  gap: theme.spacing.default,
  padding: theme.spacing.default,
}));

const InputWrapper = styled(Col)<any>(() => ({
  textAlign: "left",
  width: "100%",
}));

const Label = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head5,
  fontWeight: "bold",
  marginBottom: theme.spacing.small,
}));

export default UpdateUserContainer;
