export const COMPRESSION_OPTION = {
  maxSizeMB: 0.3,
};

export const THUMBNAIL_COMPRESSION_OPTION = {
  maxSizeMB: 0.1,
};

export const PLACE_MEDIA_COMPRESSTION_OPTION = {
  maxSizeMB: 0.5,
};

export const HOST_LIST_TAB = {
  ALL: "ALL",
  REQUESTED: "REQUESTED",
  VERIFIED: "VERIFIED",
};

export const MOIM_STATUS = {
  REVIEWING: "REVIEWING",
  EDITING: "EDITING",
  REJECTED: "REJECTED",
  ACCEPTED: "ACCEPTED",

  PENDING_LISTED: "PENDING_LISTED",

  LISTED: "LISTED",
  CONDITION_MET: "CONDITION_MET",
  ON_GOING: "ON_GOING",
  FINISHED: "FINISHED",

  ALL: "ALL",
};
