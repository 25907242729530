import { useState } from "react";
import {
  Management,
  useUpdateManagementAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useManagementValue(management: Management) {
  const [state, setState] = useState<{ [key: string]: string }>({
    homeThemeCount: management?.homeThemeCount?.toString() || "",
    mainTitle: management?.mainTitle || "",
    mainDescription: management?.mainDescription || "",
    companyName: management?.companyName || "",
    address: management?.address || "",
    email: management?.email || "",
    instagram: management?.instagram || "",
    kakao: management?.kakao || "",
    copyright: management?.copyright || "",
    mainIntroductionTitle: management?.mainIntroductionTitle || "",
    mainIntroductionSubtitle: management?.mainIntroductionSubtitle || "",
    mainIntroduction: management?.mainIntroduction || "",
    refundPolicy: management?.refundPolicy || "",
  });

  const [updateManagementAsAdmin, { loading: updateLoading }] =
    useUpdateManagementAsAdminMutation({
      onCompleted: () => {
        alert("성공!");
      },
    });

  function onInputChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSubmit() {
    const {
      homeThemeCount,
      mainTitle,
      mainDescription,
      companyName,
      address,
      email,
      instagram,
      kakao,
      copyright,
      mainIntroductionTitle,
      mainIntroductionSubtitle,
      mainIntroduction,
      refundPolicy,
    } = state;

    updateManagementAsAdmin({
      variables: {
        managementInput: {
          id: management?.id,
          languageCode: management?.languageCode,
          mainTitle,
          mainDescription,
          companyName,
          address,
          email,
          instagram,
          kakao,
          copyright,
          mainIntroductionTitle,
          mainIntroductionSubtitle,
          mainIntroduction,
          homeThemeCount: Number(homeThemeCount),
          refundPolicy,
        },
      },
    });
  }

  return {
    models: {
      state,
      updateLoading,
    },
    operations: {
      onInputChange,
      onSubmit,
    },
  };
}

export default useManagementValue;
