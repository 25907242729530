import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetAllUsersAsAdminDocument,
  useGetUserProfileLazyQuery,
  useUpdateUserProfileAsAdminMutation,
} from "../../../../lib/apollo/graphql/generated";

const defaultState = {
  id: "",
  email: "",
  username: "",
  name: "",
};

function useUpdateUserContainer() {
  const navigate = useNavigate();
  const params = useParams();
  const isNew = params?.userId === "new";

  const [state, setState] = useState<{ [key: string]: string }>(defaultState);

  const [getUserProfile, { loading }] = useGetUserProfileLazyQuery({
    onCompleted: ({ getUserProfile }) => {
      if (getUserProfile) {
        const { email, name, username } = getUserProfile;
        setState({
          id: params?.userId!,
          name: name || "",
          email: email || "",
          username,
        });
      }
    },
  });

  const [updateUserProfileAsAdmin, { loading: updateLoading }] =
    useUpdateUserProfileAsAdminMutation({
      onCompleted: () => {
        navigate(-1);
      },
      refetchQueries: () =>
        isNew
          ? [
              {
                query: GetAllUsersAsAdminDocument,
                variables: {
                  first: 20,
                },
              },
            ]
          : [],
    });

  useEffect(() => {
    if (!isNew) {
      getUserProfile({
        variables: {
          userId: params?.userId!,
        },
      });
    } else {
      setState(defaultState);
    }
  }, [params?.userId]);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSubmit() {
    const { id, name, username, email } = state;

    console.log(state);

    updateUserProfileAsAdmin({
      variables: {
        userId: id,
        userInput: {
          name,
          username,
          email,
        },
      },
    });
  }

  return {
    models: {
      state,
      loading,
      updateLoading,
    },
    operations: {
      onInputChange,
      onSubmit,
    },
  };
}

export default useUpdateUserContainer;
