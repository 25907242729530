import styled, { ThemedComponentProps } from "styled-components";

export const OptionCategoryWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    border: `1px solid ${theme.color.black2}`,
    position: "relative",
    overflow: "hidden",
  })
);

export const OptionCategoryTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
    ...theme.font.head4,
    fontWeight: "bold",
    borderBottom: `1px solid ${theme.color.black5}`,
  })
);

export const OptionsWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    height: "100%",
    overflow: "auto",
    paddingBottom: theme.spacing.section * 2,
  })
);

export const OptionAddButtonWrapper = styled.div<any>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const OptionAddButton = styled.button<any>(
  ({ theme }: ThemedComponentProps) => ({
    ...theme.font.body1,
    color: theme.color.black,
    backgroundColor: theme.color.yellow1,
    padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  })
);
