import { useState } from "react";
import { MOIM_STATUS } from "../../../../utilites/constants";
import {
  MoimStatus,
  useGetMoimsByStatusAsAdminQuery,
} from "../../../../lib/apollo/graphql/generated";

function useMoimListContainer() {
  const [tab, setTab] = useState(MOIM_STATUS.REVIEWING);

  const { data, loading, refetch, fetchMore } = useGetMoimsByStatusAsAdminQuery(
    {
      variables: {
        status: tab === "ALL" ? null : (tab as MoimStatus),
        first: 10,
      },
    }
  );

  function toggleTab(tab: string) {
    setTab(tab);
  }

  return {
    models: {
      loading,
      data: data?.getMoimsByStatusAsAdmin?.edges,
      tab,
    },
    operations: {
      toggleTab,
    },
  };
}

export default useMoimListContainer;
