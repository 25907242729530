import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useOptionInput from "./OptionInput.hook";

interface OptionInputProps {
  value: {
    id?: string;
    name?: string;
    iconUrl?: string | null | undefined;
    order?: number;
    phrase?: string;
  } | null;
  showPhraseInput?: boolean;
  onSubmit: ({
    id,
    name,
    iconUrl,
    order,
    phrase,
  }: {
    id: string | null;
    name: string;
    iconUrl: string;
    order: number;
    phrase: string;
  }) => void;
  onAdditionCancel?: () => void;
}

function OptionInput({
  value,
  showPhraseInput = false,
  onSubmit,
  onAdditionCancel,
}: OptionInputProps) {
  const {
    models: { editMode, state },
    operations: { onChange, onFileChange, onEditClick, onCancelClick },
  } = useOptionInput(value, onSubmit, onAdditionCancel);

  return (
    <Wrapper>
      <Row>
        <div>ID</div>
        <Input disabled value={value?.id} />
      </Row>
      <Row>
        <Row>
          <div>이름</div>
          <Input
            disabled={!editMode}
            value={editMode ? state.name : value?.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, "name")
            }
          />
        </Row>
        <Row>
          <div>순서</div>
          <Input
            disabled={!editMode}
            type="number"
            value={editMode ? state.order : value?.order}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, "order")
            }
          />
        </Row>
      </Row>
      {showPhraseInput && (
        <Row>
          <div>문구</div>
          <Input
            disabled={!editMode}
            value={editMode ? state.phrase : value?.phrase}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, "phrase")
            }
          />
        </Row>
      )}
      <Row>
        <div>아이콘</div>
        {value?.iconUrl && <Icon src={value?.iconUrl} />}

        {editMode && (
          <>
            <Input type="file" accept="image/*" onChange={onFileChange} />
            {state.iconState && (
              <Icon src={URL.createObjectURL(state.iconState)} />
            )}
          </>
        )}
      </Row>
      <ButtonsWrapper>
        <Button onClick={onEditClick}>수정</Button>
        {editMode && <CancelButton onClick={onCancelClick}>취소</CancelButton>}
      </ButtonsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing.xxSmall2,
  padding: `${theme.spacing.xxSmall2}px 0px`,
  borderBottom: `1px solid ${theme.color.black6}`,
  ...theme.font.body2,
  width: "100%",
}));

const Row = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.small,
  padding: `0px ${theme.spacing.xxSmall2}px`,
  width: "100%",
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  borderBottom: `1px solid ${theme.color.black7}`,
  flex: 1,
}));

const Icon = styled.img<any>(() => ({
  maxWidth: 50,
  maxHeight: 50,
  objectFit: "contain",
}));

const ButtonsWrapper = styled(Row)<any>(() => ({
  justifyContent: "flex-end",
}));

const Button = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  backgroundColor: theme.color.black2,
  ...theme.font.body3,
  color: theme.color.white,
}));

const CancelButton = styled(Button)<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.black4,
}));

export default OptionInput;
