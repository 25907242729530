import React from "react";
import styled from "styled-components";
import { ThemedComponentProps } from "styled-components";
import useManageCreateMoimFaqContainer from "./ManageCreateMoimFaqContainer.hook";
import Loading from "../../common/components/Loading";
import CreateMoimFaqCard from "../components/CreateMoimFaqCard";

function ManageCreateMoimFaqContainer() {
  const {
    models: { loading, data },
  } = useManageCreateMoimFaqContainer();

  if (loading || !data) return <Loading />;

  return (
    <Wrapper>
      {Object.keys(data).map((key) => (
        <div key={key}>
          <LanguageCode>{key}</LanguageCode>
          <CardsWrapper>
            {data[key].map((faq) => (
              <CreateMoimFaqCard key={faq?.id} faq={faq} />
            ))}
            <CreateMoimFaqCard faq={{ languageCode: key }} />
          </CardsWrapper>
        </div>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const LanguageCode = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head4,
  fontWeight: "bold",
  textAlign: "left",
  marginBottom: theme.spacing.middle,
}));

const CardsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3,1fr)",
  gridAutoRows: "auto",
  gap: theme.spacing.small,
  marginBottom: theme.spacing.middle,
}));

export default ManageCreateMoimFaqContainer;
