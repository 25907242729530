import React from "react";

function Loading() {
  return (
    <div>
      <div>응 로딩중</div>
    </div>
  );
}

export default Loading;
