import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useTopbar from "./Topbar.hook";
import { useFirebaseContext } from "../../../modules/common/providers/FirebaseProvider";
import { firebaseSignOut } from "../../firebase";

function Topbar() {
  const {
    models: { pathname },
  } = useTopbar();

  const user = useFirebaseContext();

  return (
    <Wrapper>
      <Pathname>{pathname}</Pathname>
      <AccountWrapper>
        <Account>{user?.email}</Account>
        <SignOut onClick={firebaseSignOut}>Sign Out</SignOut>
      </AccountWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
  backgroundColor: theme.color.black6,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: theme.topBarHeight,
}));

const Pathname = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head2,
  fontWeight: "bold",
}));

const AccountWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.small,
}));

const Account = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body3,
}));

const SignOut = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.small,
  backgroundColor: theme.color.black3,
  color: theme.color.white,

  "&:hover": {
    backgroundColor: theme.color.black5,
  },
}));

export default Topbar;
