import moment from "moment";
import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import MoimDetailModal from "./MoimDetailModal";
import useMoimListCard from "./MoimListCard.hook";
import { Gender, MoimStatus } from "../../../../lib/apollo/graphql/generated";

interface MoimListCardProps {
  moim: {
    __typename?: "Moim";
    id: string;
    moimStartAt?: any | null;
    moimEndAt?: any | null;
    maxPersons?: number | null;
    minPersons?: number | null;
    genderRestriction?: Gender | null;
    minAge?: number | null;
    maxAge?: number | null;
    status: MoimStatus;
    message?: string | null;
    viewCount: number;
    createdAt: any;
    updatedAt: any;
    host: {
      __typename?: "Host";
      id: string;
      bankName?: string | null;
      bankAccount?: string | null;
      introduction?: string | null;
      verified: boolean;
      idNumber?: string | null;
      createdAt: any;
      updatedAt: any;
      bankAccountMedia?: {
        __typename?: "Media";
        id: string;
        uri: string;
      } | null;
      business?: {
        __typename?: "Business";
        id: string;
        name: string;
        ownerName: string;
        registrationNumber: string;
        taxEmail: string;
        createdAt: any;
        updatedAt: any;
        registrationMedia: { __typename?: "Media"; id: string; uri: string };
      } | null;
      user: {
        __typename?: "UserProfile";
        id: string;
        username: string;
        name?: string | null;
        email?: string | null;
        phone?: string | null;
        dateOfBirth?: any | null;
        gender?: Gender | null;
        createdAt: any;
        updatedAt: any;
        nationality?: {
          __typename?: "Nationality";
          id: string;
          name: string;
        } | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    };
    place?: {
      __typename?: "Place";
      id: string;
      name?: string | null;
      address?: string | null;
      addressDetail?: string | null;
      isPrivate: boolean;
    } | null;
    moimContents?: Array<{
      __typename?: "MoimContent";
      id: string;
      languageCode: string;
      title?: string | null;
      introduction?: string | null;
      detail?: string | null;
      price?: number | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    coverPhoto?: { __typename?: "Media"; id: string; uri: string } | null;
    media: Array<{ __typename?: "Media"; id: string; uri: string } | null>;
  };
}

function MoimListCard({ moim }: MoimListCardProps) {
  const {
    models: { showModal },
    operations: { toggleShowModal },
  } = useMoimListCard();

  const {
    coverPhoto,
    moimContents,
    status,
    host,
    place,
    moimStartAt,
    moimEndAt,
    maxPersons,
    minPersons,
    minAge,
    maxAge,
    genderRestriction,
    viewCount,
  } = moim;

  const content =
    moimContents?.find((content) => content?.languageCode === "ko") ||
    moimContents?.[0];

  return (
    <Wrapper>
      {coverPhoto && <CoverPhoto src={coverPhoto?.uri} />}
      <Grid>
        <div>
          <div>
            <strong>제목</strong>
          </div>
          <div>{content?.title || ""}</div>
        </div>
        <div>
          <div>
            <strong>모임일시</strong>
          </div>
          <div>{moment(moimStartAt).format("YY-MM-DD HH:mm")}</div>
        </div>
        <div>
          <div>
            <strong>모임 상태</strong>
          </div>
          <div>{status}</div>
        </div>
        <div>
          <div>
            <strong>호스트 실명</strong>
          </div>
          <div>{host?.user?.name}</div>
        </div>
        <div>
          <div>
            <strong>호스트 유저네임</strong>
          </div>
          <div>{host?.user?.username}</div>
        </div>
        <div>
          <div>
            <strong>성별 제한</strong>
          </div>
          <div>{genderRestriction || "X"}</div>
        </div>
        <div>
          <div>
            <strong>장소 이름</strong>
          </div>
          <div>{place?.name || "X"}</div>
        </div>
        <div>
          <div>
            <strong>장소 주소</strong>
          </div>
          <div>
            {(place?.address || "") + ", " + (place?.addressDetail || "")}
          </div>
        </div>
        <div>
          <div>
            <strong>조회수</strong>
          </div>
          <div>{viewCount}</div>
        </div>
        <div>
          <div>
            <strong>연령제한</strong>
          </div>
          <div>{(minAge || "") + "~" + (maxAge || "")}</div>
        </div>
        <div>
          <div>
            <strong>인원제한</strong>
          </div>
          <div>{(minPersons || "") + "~" + (maxPersons || "")}</div>
        </div>
      </Grid>
      <button onClick={() => toggleShowModal(true)}>자세히 보기</button>
      {showModal && (
        <MoimDetailModal
          moimId={moim.id}
          isOpen={showModal}
          onRequestClose={() => toggleShowModal(false)}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black6}`,
  borderRadius: 4,
  padding: theme.spacing.small,
}));

const CoverPhoto = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: 300,
  objectFit: "cover",
  marginBottom: theme.spacing.normal,
}));

const Grid = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridAutoRows: "auto",
  gap: theme.spacing.xxSmall2,
  ...theme.font.body2,
  marginBottom: theme.spacing.small,
}));

export default MoimListCard;
