import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import useRouterContainer from "./RouterContainer.hook";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";

function RouterContainer() {
  const {
    operations: { getRoutes },
  } = useRouterContainer();

  return (
    <BrowserRouter>
      <Wrapper>
        <Sidebar />
        <RouterWrapper>
          <Topbar />
          <Router>
            <Routes>{getRoutes()}</Routes>
          </Router>
        </RouterWrapper>
      </Wrapper>
    </BrowserRouter>
  );
}

const Wrapper = styled.div<any>(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "flex-start",
}));

const RouterWrapper = styled.section(() => ({
  flex: 1,
}));

const Router = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  overflow: "auto",
  maxHeight: `calc(100vh - ${theme.topBarHeight}px)`,
}));
export default RouterContainer;
