import { useGetCreateMoimFaqsAsAdminQuery } from "../../../lib/apollo/graphql/generated";

function useManageCreateMoimFaqContainer() {
  const { loading, data } = useGetCreateMoimFaqsAsAdminQuery();

  function organizeByLanaugeCode() {
    if (!data?.getCreateMoimFaqsAsAdmin) return;

    const organized: { [key: string]: any[] } = { ko: [], en: [] };

    [...data?.getCreateMoimFaqsAsAdmin].reduce((prev, current) => {
      if (!current) {
        return prev;
      }

      const { languageCode } = current;
      if (!prev[languageCode]) {
        prev[languageCode] = [current];
      } else {
        prev[languageCode].push(current);
      }

      return prev;
    }, organized);

    return organized;
  }

  return {
    models: {
      loading,
      data: organizeByLanaugeCode(),
    },
  };
}

export default useManageCreateMoimFaqContainer;
