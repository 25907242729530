import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import useUserListContainer from "./UserListContainer.hook";
import { PATH_NAMES } from "../../../../lib/router/utils/router";
import Avatar from "../../../common/components/Avatar";
import {
  Head,
  Row,
  Table,
  TBody,
  Td,
  Th,
} from "../../../common/styles/commonStyles";

function UserListContainer() {
  const {
    models: { loading, data },
  } = useUserListContainer();

  if (loading) return <div />;

  return (
    <Wrapper>
      <ButtonsWrapper>
        <Link to={PATH_NAMES.USER.path + "/" + "new"}>유저 만들기</Link>
      </ButtonsWrapper>
      <Table>
        <Head>
          <tr>
            <Th>ID</Th>
            <Th>프사</Th>
            <Th>이메일</Th>
            <Th>유저네임</Th>
            <Th>이름</Th>
            <Th>생년월일</Th>
            <Th>성별</Th>
            <Th>국적</Th>
            <Th>호스트여부</Th>
            <Th>어드민여부</Th>
            <Th>가입일</Th>
            <Th>관리</Th>
          </tr>
        </Head>
        <TBody>
          {data?.map((user) => (
            <tr key={user?.id}>
              <Td>{user?.id}</Td>
              <Td>
                <Avatar diameter={50} uri={user?.avatar?.uri} />
              </Td>
              <Td>{user?.email}</Td>
              <Td>{user?.username || ""}</Td>
              <Td>{user?.name}</Td>
              <Td>{user?.dateOfBirth}</Td>
              <Td>{user?.gender}</Td>
              <Td>{user?.nationality?.name || ""}</Td>
              <Td>
                {!user?.host?.id
                  ? "X"
                  : user?.host?.verified
                  ? "VERIFIED"
                  : "NOT VERIFIED"}
              </Td>
              <Td>{user?.isAdmin ? "O" : "X"}</Td>
              <Td>{moment(user?.createdAt).format("YYYY-MM-DD")}</Td>
              <Td>
                <Link to={PATH_NAMES.USER.path + "/" + user?.id}>관리</Link>
              </Td>
            </tr>
          ))}
        </TBody>
      </Table>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.default,
}));

const ButtonsWrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing.small,
  marginBottom: theme.spacing.default,
}));

export default UserListContainer;
