import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Upload: any;
};

export type AdminMoimContentInput = {
  detail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  introduction?: InputMaybe<Scalars['String']>;
  languageCode: Scalars['String'];
  moimId: Scalars['ID'];
  price?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Business = {
  __typename?: 'Business';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerName: Scalars['String'];
  registrationMedia: Media;
  registrationNumber: Scalars['String'];
  taxEmail: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BusinessHour = {
  __typename?: 'BusinessHour';
  breakTimeFrom?: Maybe<Scalars['String']>;
  breakTimeTo?: Maybe<Scalars['String']>;
  dayOfWeek: Scalars['Int'];
  from: Scalars['String'];
  id: Scalars['ID'];
  place: Place;
  to: Scalars['String'];
};

export type BusinessHourInput = {
  breakTimeFrom?: InputMaybe<Scalars['String']>;
  breakTimeTo?: InputMaybe<Scalars['String']>;
  dayOfWeek: Scalars['Int'];
  from: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  to: Scalars['String'];
};

export type BusinessInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  ownerName: Scalars['String'];
  registrationMediaInput?: InputMaybe<MediaInput>;
  registrationNumber: Scalars['String'];
  taxEmail: Scalars['String'];
};

export type Community = {
  __typename?: 'Community';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  externalLink?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  host?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  media?: Maybe<Array<Maybe<Media>>>;
  title: Scalars['String'];
  to?: Maybe<Scalars['Date']>;
  updatedAt: Scalars['DateTime'];
  uploader: UserProfile;
};

export type CommunityConnection = {
  __typename?: 'CommunityConnection';
  edges: Array<Maybe<Community>>;
  pageInfo: PageInfo;
};

export type CommunityInput = {
  body: Scalars['String'];
  externalLink?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Date']>;
  host?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  to?: InputMaybe<Scalars['Date']>;
  uploaderId?: InputMaybe<Scalars['ID']>;
};

export type Content = {
  __typename?: 'Content';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fields: Array<Maybe<Field>>;
  id: Scalars['String'];
  place: Place;
  primaryMedia?: Maybe<Media>;
  readCount: Scalars['Int'];
  subtitle?: Maybe<Scalars['String']>;
  themes: Array<Maybe<Theme>>;
  thumbnail: Media;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uploader?: Maybe<UserProfile>;
  viewCount: Scalars['Int'];
};

export type ContentConnection = {
  __typename?: 'ContentConnection';
  edges: Array<Maybe<Content>>;
  pageInfo: PageInfo;
};

export type ContentInput = {
  body: Scalars['String'];
  fieldIds: Array<InputMaybe<Scalars['ID']>>;
  id?: InputMaybe<Scalars['String']>;
  placeId: Scalars['ID'];
  primaryMediaInput?: InputMaybe<MediaInput>;
  subtitle?: InputMaybe<Scalars['String']>;
  themeIds: Array<InputMaybe<Scalars['ID']>>;
  thumbnailInput?: InputMaybe<MediaInput>;
  title: Scalars['String'];
  uploaderId?: InputMaybe<Scalars['ID']>;
};

export type ContentRead = {
  __typename?: 'ContentRead';
  city?: Maybe<Scalars['String']>;
  content: Content;
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ipV4Address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<UserProfile>;
};

export type ContentReadInput = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  countryName?: InputMaybe<Scalars['String']>;
  ipV4Address?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<Scalars['String']>;
};

export enum ContentSortOption {
  Popular = 'POPULAR',
  Recent = 'RECENT'
}

export type CreateMoimFaq = {
  __typename?: 'CreateMoimFaq';
  active: Scalars['Boolean'];
  answer: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: Scalars['String'];
  question: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CreateMoimFaqInput = {
  active: Scalars['Boolean'];
  answer: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  languageCode: Scalars['String'];
  question: Scalars['String'];
};

export type EmailList = {
  __typename?: 'EmailList';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type EmailListConnection = {
  __typename?: 'EmailListConnection';
  edges: Array<Maybe<EmailList>>;
  pageInfo: PageInfo;
};

export type EmailListInput = {
  email: Scalars['String'];
};

export type Field = {
  __typename?: 'Field';
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type FieldInput = {
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type Footer = {
  __typename?: 'Footer';
  address?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  kakao?: Maybe<Scalars['String']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type HomeTopMoims = {
  __typename?: 'HomeTopMoims';
  mainIntroduction?: Maybe<Scalars['String']>;
  mainIntroductionSubtitle?: Maybe<Scalars['String']>;
  mainIntroductionTitle?: Maybe<Scalars['String']>;
  mainTitle?: Maybe<Scalars['String']>;
  top: Array<Maybe<Moim>>;
};

export type Host = {
  __typename?: 'Host';
  bankAccount?: Maybe<Scalars['String']>;
  bankAccountMedia?: Maybe<Media>;
  bankName?: Maybe<Scalars['String']>;
  business?: Maybe<Business>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  idNumber?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: UserProfile;
  verified: Scalars['Boolean'];
};

export type HostConnection = {
  __typename?: 'HostConnection';
  edges: Array<Maybe<Host>>;
  pageInfo: PageInfo;
};

export type HostInput = {
  bankAccount?: InputMaybe<Scalars['String']>;
  bankAccountMediaInput?: InputMaybe<MediaInput>;
  bankName?: InputMaybe<Scalars['String']>;
  idNumber?: InputMaybe<Scalars['String']>;
  introduction?: InputMaybe<Scalars['String']>;
};

export type Job = {
  __typename?: 'Job';
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type JobInput = {
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type Join = {
  __typename?: 'Join';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  moim: Moim;
  status: JoinStatus;
  updatedAt: Scalars['DateTime'];
  user: UserProfile;
};

export type JoinConnection = {
  __typename?: 'JoinConnection';
  edges: Array<Maybe<Join>>;
  pageInfo: PageInfo;
};

export enum JoinStatus {
  Banned = 'BANNED',
  Cancelled = 'CANCELLED',
  Joined = 'JOINED'
}

export type MainPhrases = {
  __typename?: 'MainPhrases';
  mainDescription?: Maybe<Scalars['String']>;
  mainTitle?: Maybe<Scalars['String']>;
};

export type Management = {
  __typename?: 'Management';
  address?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  homeThemeCount?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  instagram?: Maybe<Scalars['String']>;
  kakao?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  mainDescription?: Maybe<Scalars['String']>;
  mainIntroduction?: Maybe<Scalars['String']>;
  mainIntroductionSubtitle?: Maybe<Scalars['String']>;
  mainIntroductionTitle?: Maybe<Scalars['String']>;
  mainTitle?: Maybe<Scalars['String']>;
  refundPolicy?: Maybe<Scalars['String']>;
};

export type ManagementInput = {
  address?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  copyright?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  homeThemeCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  instagram?: InputMaybe<Scalars['String']>;
  kakao?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<Scalars['String']>;
  mainDescription?: InputMaybe<Scalars['String']>;
  mainIntroduction?: InputMaybe<Scalars['String']>;
  mainIntroductionSubtitle?: InputMaybe<Scalars['String']>;
  mainIntroductionTitle?: InputMaybe<Scalars['String']>;
  mainTitle?: InputMaybe<Scalars['String']>;
  refundPolicy?: InputMaybe<Scalars['String']>;
};

export type Media = {
  __typename?: 'Media';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  uri: Scalars['String'];
};

export type MediaInput = {
  file: Scalars['Upload'];
  thumbnailFile?: InputMaybe<Scalars['Upload']>;
};

export type Moim = {
  __typename?: 'Moim';
  coverPhoto?: Maybe<Media>;
  createdAt: Scalars['DateTime'];
  genderRestriction?: Maybe<Gender>;
  host: Host;
  id: Scalars['ID'];
  maxAge?: Maybe<Scalars['Int']>;
  maxPersons?: Maybe<Scalars['Int']>;
  media: Array<Maybe<Media>>;
  message?: Maybe<Scalars['String']>;
  minAge?: Maybe<Scalars['Int']>;
  minPersons?: Maybe<Scalars['Int']>;
  moimContents?: Maybe<Array<Maybe<MoimContent>>>;
  moimEndAt?: Maybe<Scalars['DateTime']>;
  moimStartAt?: Maybe<Scalars['DateTime']>;
  myReview?: Maybe<Review>;
  participants: Array<Maybe<Join>>;
  place?: Maybe<Place>;
  reviews: Array<Maybe<Review>>;
  status: MoimStatus;
  themes: Array<Maybe<Theme>>;
  updatedAt: Scalars['DateTime'];
  viewCount: Scalars['Int'];
};

export type MoimBasicInput = {
  genderRestriction?: InputMaybe<Gender>;
  hostId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: Scalars['String'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxPersons?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minPersons?: InputMaybe<Scalars['Int']>;
  moimEndAt?: InputMaybe<Scalars['DateTime']>;
  moimStartAt?: InputMaybe<Scalars['DateTime']>;
  placeId?: InputMaybe<Scalars['ID']>;
  price?: InputMaybe<Scalars['Float']>;
};

export type MoimConnection = {
  __typename?: 'MoimConnection';
  edges: Array<Maybe<Moim>>;
  pageInfo: PageInfo;
};

export type MoimContent = {
  __typename?: 'MoimContent';
  createdAt: Scalars['DateTime'];
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  introduction?: Maybe<Scalars['String']>;
  languageCode: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MoimContentInput = {
  coverPhotoInput?: InputMaybe<MediaInput>;
  detail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  introduction?: InputMaybe<Scalars['String']>;
  languageCode: Scalars['String'];
  mediaInput?: InputMaybe<Array<InputMaybe<MoimMediaInput>>>;
  moimId: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type MoimMediaInput = {
  mediaInput: MediaInput;
  order: Scalars['Int'];
};

export enum MoimStatus {
  Accepted = 'ACCEPTED',
  Editing = 'EDITING',
  Finished = 'FINISHED',
  LackOfParticipants = 'LACK_OF_PARTICIPANTS',
  Listed = 'LISTED',
  OnGoing = 'ON_GOING',
  PendingListed = 'PENDING_LISTED',
  Rejected = 'REJECTED',
  Reviewing = 'REVIEWING'
}

export type MoimThemeInput = {
  order: Scalars['Int'];
  themeId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['Boolean']>;
  addEmailList: EmailList;
  addMoimMedia: Moim;
  addPlaceMedia: Place;
  cancelJoinMoim: Join;
  createPlace: Place;
  /** Create a new question */
  createQuestion: Question;
  deleteBusinessHour: Place;
  /** Delete community */
  deleteCommunity: Scalars['Boolean'];
  /** Delete community's media */
  deleteCommunityMedia: Community;
  deleteContent: Scalars['Boolean'];
  deleteMoimCoverPhoto: Moim;
  deleteMoimMedia: Moim;
  deletePlaceMedia: Place;
  deleteUserAvatar: UserProfile;
  deleteUserAvatarAsAdmin: UserProfile;
  incrementContentReadCount: Content;
  joinMoim: Join;
  requestMoimVerification: Moim;
  requestToBeHost: Host;
  setAdminUserAsAdmin: Scalars['Boolean'];
  setMoimStatusAsAdmin: Moim;
  toggleHostVerifiedAsAdmin: Host;
  togglePlaceActive: Place;
  /** @deprecated No longer supported */
  toggleProviderActiveAsAdmin: Provider;
  /** Toggle question read as admin to track questions */
  toggleQuestionReadAsAdmin: Question;
  updateBusinessHours: Place;
  /**
   * Update community
   * if id is given, it updates
   * else it creates
   */
  updateCommunity: Community;
  /** Update community's media */
  updateCommunityMedia: Community;
  updateContentAsAdmin: Content;
  updateCreateMoimFaqAsAdmin: CreateMoimFaq;
  updateFieldAsAdmin: Field;
  updateHost: Host;
  updateJobAsAdmin: Job;
  updateManagementAsAdmin: Management;
  updateMoimBasic: Moim;
  updateMoimContent: Moim;
  updateMoimContentAsAdmin: Moim;
  updateOccupationAsAdmin: Occupation;
  updatePlace: Place;
  /** @deprecated No longer supported */
  updateProviderAsAdmin: Provider;
  updateReview: Review;
  updateThemeAsAdmin: Theme;
  updateUserAvatar: UserProfile;
  /**
   * Update user profile
   * if user profile is not found, add a new profile
   * or update user's profile
   */
  updateUserProfile: UserProfile;
  updateUserProfileAsAdmin: UserProfile;
  /**
   * Verify provider as admin
   * @deprecated No longer supported
   */
  verifyProviderAsAdmin: Provider;
};


export type MutationAddEmailListArgs = {
  emailListInput: EmailListInput;
};


export type MutationAddMoimMediaArgs = {
  mediaInput: Array<InputMaybe<MoimMediaInput>>;
  moimId: Scalars['ID'];
};


export type MutationAddPlaceMediaArgs = {
  placeId: Scalars['ID'];
  placeMediaInput: Array<InputMaybe<PlaceMediaInput>>;
};


export type MutationCancelJoinMoimArgs = {
  joinId: Scalars['ID'];
};


export type MutationCreatePlaceArgs = {
  placeInput: PlaceInput;
};


export type MutationCreateQuestionArgs = {
  questionInput: QuestionInput;
};


export type MutationDeleteBusinessHourArgs = {
  businessHourId: Scalars['ID'];
  placeId: Scalars['ID'];
};


export type MutationDeleteCommunityArgs = {
  communityId: Scalars['ID'];
};


export type MutationDeleteCommunityMediaArgs = {
  communityId: Scalars['ID'];
  mediaId: Scalars['ID'];
};


export type MutationDeleteContentArgs = {
  contentId: Scalars['ID'];
};


export type MutationDeleteMoimCoverPhotoArgs = {
  moimId: Scalars['ID'];
};


export type MutationDeleteMoimMediaArgs = {
  mediaId: Scalars['ID'];
  moimId: Scalars['ID'];
};


export type MutationDeletePlaceMediaArgs = {
  mediaId: Scalars['ID'];
  placeId: Scalars['ID'];
};


export type MutationDeleteUserAvatarAsAdminArgs = {
  userId: Scalars['ID'];
};


export type MutationIncrementContentReadCountArgs = {
  contentId: Scalars['ID'];
  contentReadInput: ContentReadInput;
};


export type MutationJoinMoimArgs = {
  moimId: Scalars['ID'];
};


export type MutationRequestMoimVerificationArgs = {
  moimId: Scalars['ID'];
};


export type MutationRequestToBeHostArgs = {
  businessInput?: InputMaybe<BusinessInput>;
  hostInput: HostInput;
};


export type MutationSetAdminUserAsAdminArgs = {
  userId: Scalars['ID'];
};


export type MutationSetMoimStatusAsAdminArgs = {
  message?: InputMaybe<Scalars['String']>;
  moimId: Scalars['ID'];
  status: MoimStatus;
};


export type MutationToggleHostVerifiedAsAdminArgs = {
  hostId: Scalars['ID'];
  verified: Scalars['Boolean'];
};


export type MutationTogglePlaceActiveArgs = {
  active: Scalars['Boolean'];
  placeId: Scalars['ID'];
};


export type MutationToggleProviderActiveAsAdminArgs = {
  active: Scalars['Boolean'];
  providerId: Scalars['ID'];
};


export type MutationToggleQuestionReadAsAdminArgs = {
  questionId: Scalars['ID'];
  read: Scalars['Boolean'];
};


export type MutationUpdateBusinessHoursArgs = {
  businessHourInputs: Array<InputMaybe<BusinessHourInput>>;
  placeId: Scalars['ID'];
};


export type MutationUpdateCommunityArgs = {
  communityInput: CommunityInput;
};


export type MutationUpdateCommunityMediaArgs = {
  communityId: Scalars['ID'];
  media: Array<InputMaybe<MediaInput>>;
};


export type MutationUpdateContentAsAdminArgs = {
  contentInput: ContentInput;
};


export type MutationUpdateCreateMoimFaqAsAdminArgs = {
  createMoimFaqInput: CreateMoimFaqInput;
};


export type MutationUpdateFieldAsAdminArgs = {
  fieldInput: FieldInput;
};


export type MutationUpdateHostArgs = {
  businessInput?: InputMaybe<BusinessInput>;
  hostInput: HostInput;
};


export type MutationUpdateJobAsAdminArgs = {
  jobInput: JobInput;
};


export type MutationUpdateManagementAsAdminArgs = {
  managementInput: ManagementInput;
};


export type MutationUpdateMoimBasicArgs = {
  moimBasicInput: MoimBasicInput;
};


export type MutationUpdateMoimContentArgs = {
  moimContentInput: MoimContentInput;
};


export type MutationUpdateMoimContentAsAdminArgs = {
  adminMoimContentInput?: InputMaybe<AdminMoimContentInput>;
};


export type MutationUpdateOccupationAsAdminArgs = {
  occupationInput: OccupationInput;
};


export type MutationUpdatePlaceArgs = {
  placeInput: PlaceInput;
};


export type MutationUpdateProviderAsAdminArgs = {
  providerInput: ProviderInput;
};


export type MutationUpdateReviewArgs = {
  reviewInput: ReviewInput;
};


export type MutationUpdateThemeAsAdminArgs = {
  themeInput: ThemeInput;
};


export type MutationUpdateUserAvatarArgs = {
  avatarInput: MediaInput;
};


export type MutationUpdateUserProfileArgs = {
  userInput: UserInput;
};


export type MutationUpdateUserProfileAsAdminArgs = {
  userId?: InputMaybe<Scalars['ID']>;
  userInput: UserInput;
};


export type MutationVerifyProviderAsAdminArgs = {
  providerId: Scalars['ID'];
  verified: Scalars['Boolean'];
};

export type Nationality = {
  __typename?: 'Nationality';
  emoji?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  unicode: Scalars['String'];
  users: Array<Maybe<UserProfile>>;
};

export type Occupation = {
  __typename?: 'Occupation';
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type OccupationInput = {
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type Place = {
  __typename?: 'Place';
  active: Scalars['Boolean'];
  address?: Maybe<Scalars['String']>;
  addressDetail?: Maybe<Scalars['String']>;
  businessHours?: Maybe<Array<Maybe<BusinessHour>>>;
  createdAt: Scalars['DateTime'];
  field?: Maybe<Field>;
  id: Scalars['ID'];
  isPrivate: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  media?: Maybe<Array<Maybe<Media>>>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PlaceConnection = {
  __typename?: 'PlaceConnection';
  edges: Array<Maybe<Place>>;
  pageInfo: PageInfo;
};

export type PlaceInput = {
  address?: InputMaybe<Scalars['String']>;
  addressDetail?: InputMaybe<Scalars['String']>;
  fieldId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type PlaceMediaInput = {
  mediaInput: MediaInput;
  order: Scalars['Int'];
};

export type Provider = {
  __typename?: 'Provider';
  active: Scalars['Boolean'];
  avatar?: Maybe<Media>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerName: Scalars['String'];
  phone: Scalars['String'];
  registrationMedia?: Maybe<Media>;
  registrationNumber?: Maybe<Scalars['String']>;
  superUser: UserProfile;
  type: ProviderType;
  updatedAt: Scalars['DateTime'];
  verified: Scalars['Boolean'];
};

export type ProviderConnection = {
  __typename?: 'ProviderConnection';
  edges: Array<Maybe<Provider>>;
  pageInfo: PageInfo;
};

export type ProviderInput = {
  avatarInput?: InputMaybe<MediaInput>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  ownerName: Scalars['String'];
  phone: Scalars['String'];
  registrationMediaInput?: InputMaybe<MediaInput>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  superUserId?: InputMaybe<Scalars['ID']>;
  type: ProviderType;
};

export enum ProviderType {
  Cooperate = 'COOPERATE',
  Personal = 'PERSONAL',
  PersonalBusiness = 'PERSONAL_BUSINESS'
}

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['Boolean']>;
  /** Get all users as admin(only admin can access) */
  getAllUsersAsAdmin: UserConnection;
  getCommonTheme: Theme;
  /** Get all communities */
  getCommunities: CommunityConnection;
  /** Get the community by given id */
  getCommunity: Community;
  getContent: Content;
  getContentFields: Array<Maybe<Field>>;
  getContentReadByContentIdAsAdmin: Array<Maybe<ContentRead>>;
  getContentThemes: Array<Maybe<Theme>>;
  getContents: ContentConnection;
  getCreateMoimFaqs?: Maybe<Array<Maybe<CreateMoimFaq>>>;
  getCreateMoimFaqsAsAdmin?: Maybe<Array<Maybe<CreateMoimFaq>>>;
  getEmailListsAsAdmin: EmailListConnection;
  getEmailListsCountAsAdmin: Scalars['Int'];
  getFields: Array<Maybe<Field>>;
  getFooter?: Maybe<Footer>;
  getHomeMoims: MoimConnection;
  getHomeRecentContents: Array<Maybe<Content>>;
  getHomeThemes: Array<Maybe<Theme>>;
  getHomeTopMoims: HomeTopMoims;
  getHost: Host;
  getHostsByVerification: HostConnection;
  getJobs: Array<Maybe<Job>>;
  /** @deprecated No longer supported */
  getMainPhrases: MainPhrases;
  getManagementsAsAdmin: Array<Maybe<Management>>;
  getMoim: Moim;
  getMoimParticipants: Array<Maybe<Join>>;
  getMoimsByStatusAsAdmin: MoimConnection;
  getMyCreatedMoims?: Maybe<MoimConnection>;
  getMyJoinedMoims: JoinConnection;
  getMyReviews: ReviewConnection;
  /** Get current UserProfile's profile */
  getMyUserProfile?: Maybe<UserProfile>;
  getOccupations: Array<Maybe<Occupation>>;
  /** Get other communities except current community by id */
  getOtherCommunities: Array<Maybe<Community>>;
  getPlace: Place;
  getPlaces: PlaceConnection;
  /** @deprecated No longer supported */
  getProvider: Provider;
  /** @deprecated No longer supported */
  getProviderTypes: Array<Maybe<ProviderType>>;
  /** @deprecated No longer supported */
  getProvidersByType: ProviderConnection;
  /** Get the question by id */
  getQuestionAsAdmin: Question;
  getQuestionTypes: Array<Maybe<QuestionType>>;
  /** Get questions from user as admin */
  getQuestionsAsAdmin: QuestionConnection;
  getRecommendedContentsByContentId: Array<Maybe<Content>>;
  getRefundPolicy?: Maybe<Scalars['String']>;
  getThemes: Array<Maybe<Theme>>;
  /** Get current UserProfile's profile */
  getUserProfile?: Maybe<UserProfile>;
  isUniqueUsername: Scalars['Boolean'];
  searchContents: ContentConnection;
  searchFields: Array<Maybe<Field>>;
  searchNationality: Array<Maybe<Nationality>>;
  searchPlaces: PlaceConnection;
  /** @deprecated No longer supported */
  searchProvider: ProviderConnection;
  searchUser: UserConnection;
};


export type QueryGetAllUsersAsAdminArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommunityArgs = {
  communityId: Scalars['ID'];
};


export type QueryGetContentArgs = {
  contentId: Scalars['ID'];
};


export type QueryGetContentReadByContentIdAsAdminArgs = {
  contentId: Scalars['ID'];
};


export type QueryGetContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  fieldId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ContentSortOption>;
  themeId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetCreateMoimFaqsArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};


export type QueryGetEmailListsAsAdminArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetFooterArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};


export type QueryGetHomeMoimsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetHomeTopMoimsArgs = {
  languageCode: Scalars['String'];
};


export type QueryGetHostArgs = {
  hostId: Scalars['ID'];
};


export type QueryGetHostsByVerificationArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetMoimArgs = {
  moimId: Scalars['ID'];
};


export type QueryGetMoimParticipantsArgs = {
  moimId: Scalars['ID'];
};


export type QueryGetMoimsByStatusAsAdminArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<MoimStatus>;
};


export type QueryGetMyCreatedMoimsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  moimStatus?: InputMaybe<MoimStatus>;
};


export type QueryGetMyJoinedMoimsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  moimStatus?: InputMaybe<MoimStatus>;
};


export type QueryGetMyReviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetOtherCommunitiesArgs = {
  communityId: Scalars['ID'];
};


export type QueryGetPlaceArgs = {
  placeId: Scalars['ID'];
};


export type QueryGetPlacesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetProviderArgs = {
  providerId: Scalars['ID'];
};


export type QueryGetProvidersByTypeArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ProviderType>;
};


export type QueryGetQuestionAsAdminArgs = {
  questionId: Scalars['ID'];
};


export type QueryGetQuestionsAsAdminArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<QuestionType>;
};


export type QueryGetRecommendedContentsByContentIdArgs = {
  contentId: Scalars['ID'];
};


export type QueryGetRefundPolicyArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserProfileArgs = {
  userId: Scalars['ID'];
};


export type QueryIsUniqueUsernameArgs = {
  username: Scalars['String'];
};


export type QuerySearchContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type QuerySearchFieldsArgs = {
  query: Scalars['String'];
};


export type QuerySearchNationalityArgs = {
  query: Scalars['String'];
};


export type QuerySearchPlacesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type QuerySearchProviderArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type QuerySearchUserArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  read: Scalars['Boolean'];
  to?: Maybe<Scalars['Date']>;
  type: QuestionType;
  updatedAt: Scalars['DateTime'];
};

export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  edges: Array<Maybe<Question>>;
  pageInfo: PageInfo;
};

/** From, to should be set when type is EXPERIENCE */
export type QuestionInput = {
  body: Scalars['String'];
  email: Scalars['String'];
  from?: InputMaybe<Scalars['Date']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  to?: InputMaybe<Scalars['Date']>;
  type: QuestionType;
};

export enum QuestionType {
  Book = 'BOOK',
  Collaboration = 'COLLABORATION',
  Experience = 'EXPERIENCE',
  Register = 'REGISTER',
  Service = 'SERVICE'
}

export type Review = {
  __typename?: 'Review';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  moim: Moim;
  rating?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  writer: UserProfile;
};

export type ReviewConnection = {
  __typename?: 'ReviewConnection';
  edges: Array<Maybe<Review>>;
  pageInfo: PageInfo;
};

export type ReviewInput = {
  body: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  moimId: Scalars['ID'];
  rating?: InputMaybe<Scalars['Int']>;
};

export type Theme = {
  __typename?: 'Theme';
  contents?: Maybe<Array<Maybe<Content>>>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  phrase: Scalars['String'];
};


export type ThemeContentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type ThemeInput = {
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  phrase: Scalars['String'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<Maybe<UserProfile>>;
  pageInfo: PageInfo;
};

export type UserInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  name?: InputMaybe<Scalars['String']>;
  nationalityId?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['ID']>;
  username: Scalars['String'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  avatar?: Maybe<Media>;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  host?: Maybe<Host>;
  id: Scalars['ID'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  joins: Array<Maybe<Join>>;
  name?: Maybe<Scalars['String']>;
  nationality?: Maybe<Nationality>;
  phone?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  reviewCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type GetCommunitiesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetCommunitiesQuery = { __typename?: 'Query', getCommunities: { __typename?: 'CommunityConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Community', id: string, title: string, from?: any | null, to?: any | null, host?: string | null, createdAt: any, uploader: { __typename?: 'UserProfile', id: string, name?: string | null }, media?: Array<{ __typename?: 'Media', id: string, thumbnailUri?: string | null } | null> | null } | null> } };

export type GetCommunityQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;


export type GetCommunityQuery = { __typename?: 'Query', getCommunity: { __typename?: 'Community', id: string, title: string, body: string, from?: any | null, to?: any | null, host?: string | null, externalLink?: string | null, createdAt: any, updatedAt: any, uploader: { __typename?: 'UserProfile', id: string, name?: string | null }, media?: Array<{ __typename?: 'Media', id: string, thumbnailUri?: string | null, uri: string } | null> | null } };

export type UpdateCommunityMutationVariables = Exact<{
  communityInput: CommunityInput;
}>;


export type UpdateCommunityMutation = { __typename?: 'Mutation', updateCommunity: { __typename?: 'Community', id: string, title: string, body: string, from?: any | null, to?: any | null, host?: string | null, externalLink?: string | null, createdAt: any, updatedAt: any, uploader: { __typename?: 'UserProfile', id: string, name?: string | null }, media?: Array<{ __typename?: 'Media', id: string, thumbnailUri?: string | null, uri: string } | null> | null } };

export type UpdateCommunityMediaMutationVariables = Exact<{
  communityId: Scalars['ID'];
  media: Array<InputMaybe<MediaInput>> | InputMaybe<MediaInput>;
}>;


export type UpdateCommunityMediaMutation = { __typename?: 'Mutation', updateCommunityMedia: { __typename?: 'Community', id: string, title: string, body: string, from?: any | null, to?: any | null, host?: string | null, externalLink?: string | null, createdAt: any, updatedAt: any, uploader: { __typename?: 'UserProfile', id: string, name?: string | null }, media?: Array<{ __typename?: 'Media', id: string, thumbnailUri?: string | null, uri: string } | null> | null } };

export type DeleteCommunityMediaMutationVariables = Exact<{
  communityId: Scalars['ID'];
  mediaId: Scalars['ID'];
}>;


export type DeleteCommunityMediaMutation = { __typename?: 'Mutation', deleteCommunityMedia: { __typename?: 'Community', id: string, title: string, body: string, from?: any | null, to?: any | null, host?: string | null, externalLink?: string | null, createdAt: any, updatedAt: any, uploader: { __typename?: 'UserProfile', id: string, name?: string | null }, media?: Array<{ __typename?: 'Media', id: string, thumbnailUri?: string | null, uri: string } | null> | null } };

export type DeleteCommunityMutationVariables = Exact<{
  communityId: Scalars['ID'];
}>;


export type DeleteCommunityMutation = { __typename?: 'Mutation', deleteCommunity: boolean };

export type UpdateContentAsAdminMutationVariables = Exact<{
  contentInput: ContentInput;
}>;


export type UpdateContentAsAdminMutation = { __typename?: 'Mutation', updateContentAsAdmin: { __typename?: 'Content', id: string, title: string, subtitle?: string | null, body: string, viewCount: number, readCount: number, createdAt: any, updatedAt: any, place: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null }, uploader?: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, isAdmin?: boolean | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null, host?: { __typename?: 'Host', id: string, verified: boolean } | null } | null, thumbnail: { __typename?: 'Media', id: string, uri: string, thumbnailUri?: string | null }, primaryMedia?: { __typename?: 'Media', id: string, uri: string, thumbnailUri?: string | null } | null, fields: Array<{ __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null>, themes: Array<{ __typename?: 'Theme', id: string, name: string, phrase: string, iconUrl?: string | null, order: number } | null> } };

export type DeleteContentMutationVariables = Exact<{
  contentId: Scalars['ID'];
}>;


export type DeleteContentMutation = { __typename?: 'Mutation', deleteContent: boolean };

export type GetContentsQueryVariables = Exact<{
  fieldId?: InputMaybe<Scalars['ID']>;
  themeId?: InputMaybe<Scalars['ID']>;
  sort?: InputMaybe<ContentSortOption>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetContentsQuery = { __typename?: 'Query', getContents: { __typename?: 'ContentConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Content', id: string, title: string, subtitle?: string | null, body: string, viewCount: number, readCount: number, createdAt: any, updatedAt: any, place: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null }, uploader?: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, isAdmin?: boolean | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null, host?: { __typename?: 'Host', id: string, verified: boolean } | null } | null, thumbnail: { __typename?: 'Media', id: string, uri: string, thumbnailUri?: string | null }, primaryMedia?: { __typename?: 'Media', id: string, uri: string, thumbnailUri?: string | null } | null, fields: Array<{ __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null>, themes: Array<{ __typename?: 'Theme', id: string, name: string, phrase: string, iconUrl?: string | null, order: number } | null> } | null> } };

export type GetContentQueryVariables = Exact<{
  contentId: Scalars['ID'];
}>;


export type GetContentQuery = { __typename?: 'Query', getContent: { __typename?: 'Content', id: string, title: string, subtitle?: string | null, body: string, viewCount: number, readCount: number, createdAt: any, updatedAt: any, place: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null }, uploader?: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, isAdmin?: boolean | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null, host?: { __typename?: 'Host', id: string, verified: boolean } | null } | null, thumbnail: { __typename?: 'Media', id: string, uri: string, thumbnailUri?: string | null }, primaryMedia?: { __typename?: 'Media', id: string, uri: string, thumbnailUri?: string | null } | null, fields: Array<{ __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null>, themes: Array<{ __typename?: 'Theme', id: string, name: string, phrase: string, iconUrl?: string | null, order: number } | null> } };

export type SearchContentsQueryVariables = Exact<{
  query: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type SearchContentsQuery = { __typename?: 'Query', searchContents: { __typename?: 'ContentConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Content', id: string, title: string, subtitle?: string | null, body: string, viewCount: number, readCount: number, createdAt: any, updatedAt: any, place: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null }, uploader?: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, isAdmin?: boolean | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null, host?: { __typename?: 'Host', id: string, verified: boolean } | null } | null, thumbnail: { __typename?: 'Media', id: string, uri: string, thumbnailUri?: string | null }, primaryMedia?: { __typename?: 'Media', id: string, uri: string, thumbnailUri?: string | null } | null, fields: Array<{ __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null>, themes: Array<{ __typename?: 'Theme', id: string, name: string, phrase: string, iconUrl?: string | null, order: number } | null> } | null> } };

export type GetContentReadByContentIdAsAdminQueryVariables = Exact<{
  contentId: Scalars['ID'];
}>;


export type GetContentReadByContentIdAsAdminQuery = { __typename?: 'Query', getContentReadByContentIdAsAdmin: Array<{ __typename?: 'ContentRead', id: string, ipV4Address?: string | null, countryCode?: string | null, state?: string | null, countryName?: string | null, city?: string | null, latitude?: number | null, longitude?: number | null, user?: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null } | null, content: { __typename?: 'Content', id: string, title: string, readCount: number, viewCount: number } } | null> };

export type GetEmailListsAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetEmailListsAsAdminQuery = { __typename?: 'Query', getEmailListsAsAdmin: { __typename?: 'EmailListConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'EmailList', id: string, email: string, createdAt: any, updatedAt: any } | null> } };

export type GetEmailListsCountAsAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailListsCountAsAdminQuery = { __typename?: 'Query', getEmailListsCountAsAdmin: number };

export type GetFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFieldsQuery = { __typename?: 'Query', getFields: Array<{ __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null> };

export type UpdateFieldAsAdminMutationVariables = Exact<{
  fieldInput: FieldInput;
}>;


export type UpdateFieldAsAdminMutation = { __typename?: 'Mutation', updateFieldAsAdmin: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } };

export type UserProfileFieldFragment = { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, isAdmin?: boolean | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null, host?: { __typename?: 'Host', id: string, verified: boolean } | null };

export type PageInfoFieldFragment = { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null };

export type CommunityFieldFragment = { __typename?: 'Community', id: string, title: string, body: string, from?: any | null, to?: any | null, host?: string | null, externalLink?: string | null, createdAt: any, updatedAt: any, uploader: { __typename?: 'UserProfile', id: string, name?: string | null }, media?: Array<{ __typename?: 'Media', id: string, thumbnailUri?: string | null, uri: string } | null> | null };

export type CommunityThumbnailFieldFragment = { __typename?: 'Community', id: string, title: string, from?: any | null, to?: any | null, host?: string | null, createdAt: any, uploader: { __typename?: 'UserProfile', id: string, name?: string | null }, media?: Array<{ __typename?: 'Media', id: string, thumbnailUri?: string | null } | null> | null };

export type QuestionFieldFragment = { __typename?: 'Question', id: string, type: QuestionType, name: string, email: string, phone: string, body: string, from?: any | null, to?: any | null, read: boolean, createdAt: any, updatedAt: any };

export type ProviderFieldFragment = { __typename?: 'Provider', id: string, type: ProviderType, name: string, registrationNumber?: string | null, ownerName: string, phone: string, email: string, verified: boolean, active: boolean, createdAt: any, updatedAt: any, registrationMedia?: { __typename?: 'Media', id: string, uri: string } | null, superUser: { __typename?: 'UserProfile', id: string, name?: string | null, email?: string | null, createdAt: any, updatedAt: any }, avatar?: { __typename?: 'Media', id: string, uri: string } | null };

export type FieldFieldFragment = { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number };

export type JobFieldFragment = { __typename?: 'Job', id: string, name: string, iconUrl?: string | null, order: number };

export type OccupationFieldFragment = { __typename?: 'Occupation', id: string, name: string, iconUrl?: string | null, order: number };

export type ThemeFieldFragment = { __typename?: 'Theme', id: string, name: string, phrase: string, iconUrl?: string | null, order: number };

export type BusinessHourFieldFragment = { __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number };

export type PlaceFieldFragment = { __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null };

export type ContentFieldFragment = { __typename?: 'Content', id: string, title: string, subtitle?: string | null, body: string, viewCount: number, readCount: number, createdAt: any, updatedAt: any, place: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null }, uploader?: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, isAdmin?: boolean | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null, host?: { __typename?: 'Host', id: string, verified: boolean } | null } | null, thumbnail: { __typename?: 'Media', id: string, uri: string, thumbnailUri?: string | null }, primaryMedia?: { __typename?: 'Media', id: string, uri: string, thumbnailUri?: string | null } | null, fields: Array<{ __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null>, themes: Array<{ __typename?: 'Theme', id: string, name: string, phrase: string, iconUrl?: string | null, order: number } | null> };

export type ManagementFieldFragment = { __typename?: 'Management', id: number, languageCode?: string | null, homeThemeCount?: number | null, mainTitle?: string | null, mainDescription?: string | null, companyName?: string | null, address?: string | null, email?: string | null, instagram?: string | null, kakao?: string | null, copyright?: string | null, mainIntroductionTitle?: string | null, mainIntroductionSubtitle?: string | null, mainIntroduction?: string | null, refundPolicy?: string | null };

export type EmailListFieldFragment = { __typename?: 'EmailList', id: string, email: string, createdAt: any, updatedAt: any };

export type CreateMoimFaqFieldFragment = { __typename?: 'CreateMoimFaq', id: string, question: string, answer: string, active: boolean, languageCode: string, createdAt: any, updatedAt: any };

export type GetHostsByVerificationQueryVariables = Exact<{
  verified?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetHostsByVerificationQuery = { __typename?: 'Query', getHostsByVerification: { __typename?: 'HostConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Host', id: string, bankName?: string | null, bankAccount?: string | null, introduction?: string | null, verified: boolean, idNumber?: string | null, createdAt: any, updatedAt: any, bankAccountMedia?: { __typename?: 'Media', id: string, uri: string } | null, business?: { __typename?: 'Business', id: string, name: string, ownerName: string, registrationNumber: string, taxEmail: string, createdAt: any, updatedAt: any, registrationMedia: { __typename?: 'Media', id: string, uri: string } } | null, user: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null } } | null> } };

export type ToggleHostVerifiedAsAdminMutationVariables = Exact<{
  hostId: Scalars['ID'];
  verified: Scalars['Boolean'];
}>;


export type ToggleHostVerifiedAsAdminMutation = { __typename?: 'Mutation', toggleHostVerifiedAsAdmin: { __typename?: 'Host', id: string, verified: boolean } };

export type GetJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetJobsQuery = { __typename?: 'Query', getJobs: Array<{ __typename?: 'Job', id: string, name: string, iconUrl?: string | null, order: number } | null> };

export type UpdateJobAsAdminMutationVariables = Exact<{
  jobInput: JobInput;
}>;


export type UpdateJobAsAdminMutation = { __typename?: 'Mutation', updateJobAsAdmin: { __typename?: 'Job', id: string, name: string, iconUrl?: string | null, order: number } };

export type UpdateManagementAsAdminMutationVariables = Exact<{
  managementInput: ManagementInput;
}>;


export type UpdateManagementAsAdminMutation = { __typename?: 'Mutation', updateManagementAsAdmin: { __typename?: 'Management', id: number, languageCode?: string | null, homeThemeCount?: number | null, mainTitle?: string | null, mainDescription?: string | null, companyName?: string | null, address?: string | null, email?: string | null, instagram?: string | null, kakao?: string | null, copyright?: string | null, mainIntroductionTitle?: string | null, mainIntroductionSubtitle?: string | null, mainIntroduction?: string | null, refundPolicy?: string | null } };

export type GetManagementsAsAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManagementsAsAdminQuery = { __typename?: 'Query', getManagementsAsAdmin: Array<{ __typename?: 'Management', id: number, languageCode?: string | null, homeThemeCount?: number | null, mainTitle?: string | null, mainDescription?: string | null, companyName?: string | null, address?: string | null, email?: string | null, instagram?: string | null, kakao?: string | null, copyright?: string | null, mainIntroductionTitle?: string | null, mainIntroductionSubtitle?: string | null, mainIntroduction?: string | null, refundPolicy?: string | null } | null> };

export type GetCreateMoimFaqsAsAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreateMoimFaqsAsAdminQuery = { __typename?: 'Query', getCreateMoimFaqsAsAdmin?: Array<{ __typename?: 'CreateMoimFaq', id: string, question: string, answer: string, active: boolean, languageCode: string, createdAt: any, updatedAt: any } | null> | null };

export type UpdateCreateMoimFaqAsAdminMutationVariables = Exact<{
  createMoimFaqInput: CreateMoimFaqInput;
}>;


export type UpdateCreateMoimFaqAsAdminMutation = { __typename?: 'Mutation', updateCreateMoimFaqAsAdmin: { __typename?: 'CreateMoimFaq', id: string, question: string, answer: string, active: boolean, languageCode: string, createdAt: any, updatedAt: any } };

export type GetMoimsByStatusAsAdminQueryVariables = Exact<{
  status?: InputMaybe<MoimStatus>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetMoimsByStatusAsAdminQuery = { __typename?: 'Query', getMoimsByStatusAsAdmin: { __typename?: 'MoimConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Moim', id: string, moimStartAt?: any | null, moimEndAt?: any | null, maxPersons?: number | null, minPersons?: number | null, genderRestriction?: Gender | null, minAge?: number | null, maxAge?: number | null, status: MoimStatus, message?: string | null, viewCount: number, createdAt: any, updatedAt: any, host: { __typename?: 'Host', id: string, bankName?: string | null, bankAccount?: string | null, introduction?: string | null, verified: boolean, idNumber?: string | null, createdAt: any, updatedAt: any, bankAccountMedia?: { __typename?: 'Media', id: string, uri: string } | null, business?: { __typename?: 'Business', id: string, name: string, ownerName: string, registrationNumber: string, taxEmail: string, createdAt: any, updatedAt: any, registrationMedia: { __typename?: 'Media', id: string, uri: string } } | null, user: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null } }, place?: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, addressDetail?: string | null, isPrivate: boolean } | null, moimContents?: Array<{ __typename?: 'MoimContent', id: string, languageCode: string, title?: string | null, introduction?: string | null, detail?: string | null, price?: number | null, createdAt: any, updatedAt: any } | null> | null, coverPhoto?: { __typename?: 'Media', id: string, uri: string } | null, media: Array<{ __typename?: 'Media', id: string, uri: string } | null> } | null> } };

export type GetMoimQueryVariables = Exact<{
  moimId: Scalars['ID'];
}>;


export type GetMoimQuery = { __typename?: 'Query', getMoim: { __typename?: 'Moim', id: string, moimStartAt?: any | null, moimEndAt?: any | null, maxPersons?: number | null, minPersons?: number | null, genderRestriction?: Gender | null, minAge?: number | null, maxAge?: number | null, status: MoimStatus, message?: string | null, viewCount: number, createdAt: any, updatedAt: any, host: { __typename?: 'Host', id: string, bankName?: string | null, bankAccount?: string | null, introduction?: string | null, verified: boolean, idNumber?: string | null, createdAt: any, updatedAt: any, bankAccountMedia?: { __typename?: 'Media', id: string, uri: string } | null, business?: { __typename?: 'Business', id: string, name: string, ownerName: string, registrationNumber: string, taxEmail: string, createdAt: any, updatedAt: any, registrationMedia: { __typename?: 'Media', id: string, uri: string } } | null, user: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null } }, place?: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, addressDetail?: string | null, isPrivate: boolean } | null, moimContents?: Array<{ __typename?: 'MoimContent', id: string, languageCode: string, title?: string | null, introduction?: string | null, detail?: string | null, price?: number | null, createdAt: any, updatedAt: any } | null> | null, coverPhoto?: { __typename?: 'Media', id: string, uri: string } | null, media: Array<{ __typename?: 'Media', id: string, uri: string } | null> } };

export type UpdateMoimContentAsAdminMutationVariables = Exact<{
  adminMoimContentInput?: InputMaybe<AdminMoimContentInput>;
}>;


export type UpdateMoimContentAsAdminMutation = { __typename?: 'Mutation', updateMoimContentAsAdmin: { __typename?: 'Moim', id: string, moimContents?: Array<{ __typename?: 'MoimContent', id: string, languageCode: string, title?: string | null, introduction?: string | null, detail?: string | null, price?: number | null, createdAt: any, updatedAt: any } | null> | null } };

export type SetMoimStatusAsAdminMutationVariables = Exact<{
  moimId: Scalars['ID'];
  status: MoimStatus;
  message?: InputMaybe<Scalars['String']>;
}>;


export type SetMoimStatusAsAdminMutation = { __typename?: 'Mutation', setMoimStatusAsAdmin: { __typename?: 'Moim', id: string, status: MoimStatus, message?: string | null } };

export type GetOccupationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOccupationsQuery = { __typename?: 'Query', getOccupations: Array<{ __typename?: 'Occupation', id: string, name: string, iconUrl?: string | null, order: number } | null> };

export type UpdateOccupationAsAdminMutationVariables = Exact<{
  occupationInput: OccupationInput;
}>;


export type UpdateOccupationAsAdminMutation = { __typename?: 'Mutation', updateOccupationAsAdmin: { __typename?: 'Occupation', id: string, name: string, iconUrl?: string | null, order: number } };

export type CreatePlaceMutationVariables = Exact<{
  placeInput: PlaceInput;
}>;


export type CreatePlaceMutation = { __typename?: 'Mutation', createPlace: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null } };

export type UpdatePlaceMutationVariables = Exact<{
  placeInput: PlaceInput;
}>;


export type UpdatePlaceMutation = { __typename?: 'Mutation', updatePlace: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null } };

export type AddPlaceMediaMutationVariables = Exact<{
  placeId: Scalars['ID'];
  placeMediaInput: Array<InputMaybe<PlaceMediaInput>> | InputMaybe<PlaceMediaInput>;
}>;


export type AddPlaceMediaMutation = { __typename?: 'Mutation', addPlaceMedia: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null } };

export type DeletePlaceMediaMutationVariables = Exact<{
  placeId: Scalars['ID'];
  mediaId: Scalars['ID'];
}>;


export type DeletePlaceMediaMutation = { __typename?: 'Mutation', deletePlaceMedia: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null } };

export type GetPlaceQueryVariables = Exact<{
  placeId: Scalars['ID'];
}>;


export type GetPlaceQuery = { __typename?: 'Query', getPlace: { __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null } };

export type GetPlacesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetPlacesQuery = { __typename?: 'Query', getPlaces: { __typename?: 'PlaceConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null } | null> } };

export type SearchPlacesQueryVariables = Exact<{
  query: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type SearchPlacesQuery = { __typename?: 'Query', searchPlaces: { __typename?: 'PlaceConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Place', id: string, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, phone?: string | null, active: boolean, createdAt: any, updatedAt: any, field?: { __typename?: 'Field', id: string, name: string, iconUrl?: string | null, order: number } | null, media?: Array<{ __typename?: 'Media', id: string, uri: string } | null> | null, businessHours?: Array<{ __typename?: 'BusinessHour', id: string, from: string, to: string, breakTimeFrom?: string | null, breakTimeTo?: string | null, dayOfWeek: number } | null> | null } | null> } };

export type SearchProviderQueryVariables = Exact<{
  query: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type SearchProviderQuery = { __typename?: 'Query', searchProvider: { __typename?: 'ProviderConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Provider', id: string, type: ProviderType, name: string, registrationNumber?: string | null, ownerName: string, phone: string, email: string, verified: boolean, active: boolean, createdAt: any, updatedAt: any, registrationMedia?: { __typename?: 'Media', id: string, uri: string } | null, superUser: { __typename?: 'UserProfile', id: string, name?: string | null, email?: string | null, createdAt: any, updatedAt: any }, avatar?: { __typename?: 'Media', id: string, uri: string } | null } | null> } };

export type GetProviderTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProviderTypesQuery = { __typename?: 'Query', getProviderTypes: Array<ProviderType | null> };

export type UpdateProviderAsAdminMutationVariables = Exact<{
  providerInput: ProviderInput;
}>;


export type UpdateProviderAsAdminMutation = { __typename?: 'Mutation', updateProviderAsAdmin: { __typename?: 'Provider', id: string, type: ProviderType, name: string, registrationNumber?: string | null, ownerName: string, phone: string, email: string, verified: boolean, active: boolean, createdAt: any, updatedAt: any, registrationMedia?: { __typename?: 'Media', id: string, uri: string } | null, superUser: { __typename?: 'UserProfile', id: string, name?: string | null, email?: string | null, createdAt: any, updatedAt: any }, avatar?: { __typename?: 'Media', id: string, uri: string } | null } };

export type GetProviderQueryVariables = Exact<{
  providerId: Scalars['ID'];
}>;


export type GetProviderQuery = { __typename?: 'Query', getProvider: { __typename?: 'Provider', id: string, type: ProviderType, name: string, registrationNumber?: string | null, ownerName: string, phone: string, email: string, verified: boolean, active: boolean, createdAt: any, updatedAt: any, registrationMedia?: { __typename?: 'Media', id: string, uri: string } | null, superUser: { __typename?: 'UserProfile', id: string, name?: string | null, email?: string | null, createdAt: any, updatedAt: any }, avatar?: { __typename?: 'Media', id: string, uri: string } | null } };

export type GetProvidersByTypeQueryVariables = Exact<{
  type?: InputMaybe<ProviderType>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetProvidersByTypeQuery = { __typename?: 'Query', getProvidersByType: { __typename?: 'ProviderConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Provider', id: string, type: ProviderType, name: string, registrationNumber?: string | null, ownerName: string, phone: string, email: string, verified: boolean, active: boolean, createdAt: any, updatedAt: any, registrationMedia?: { __typename?: 'Media', id: string, uri: string } | null, superUser: { __typename?: 'UserProfile', id: string, name?: string | null, email?: string | null, createdAt: any, updatedAt: any }, avatar?: { __typename?: 'Media', id: string, uri: string } | null } | null> } };

export type VerifyProviderAsAdminMutationVariables = Exact<{
  providerId: Scalars['ID'];
  verified: Scalars['Boolean'];
}>;


export type VerifyProviderAsAdminMutation = { __typename?: 'Mutation', verifyProviderAsAdmin: { __typename?: 'Provider', id: string, verified: boolean } };

export type ToggleProviderActiveAsAdminMutationVariables = Exact<{
  providerId: Scalars['ID'];
  active: Scalars['Boolean'];
}>;


export type ToggleProviderActiveAsAdminMutation = { __typename?: 'Mutation', toggleProviderActiveAsAdmin: { __typename?: 'Provider', id: string, active: boolean } };

export type GetQuestionsAsAdminQueryVariables = Exact<{
  type?: InputMaybe<QuestionType>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetQuestionsAsAdminQuery = { __typename?: 'Query', getQuestionsAsAdmin: { __typename?: 'QuestionConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Question', id: string, type: QuestionType, name: string, email: string, phone: string, body: string, from?: any | null, to?: any | null, read: boolean, createdAt: any, updatedAt: any } | null> } };

export type ToggleQuestionReadAsAdminMutationVariables = Exact<{
  questionId: Scalars['ID'];
  read: Scalars['Boolean'];
}>;


export type ToggleQuestionReadAsAdminMutation = { __typename?: 'Mutation', toggleQuestionReadAsAdmin: { __typename?: 'Question', id: string, read: boolean } };

export type GetThemesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetThemesQuery = { __typename?: 'Query', getThemes: Array<{ __typename?: 'Theme', id: string, name: string, phrase: string, iconUrl?: string | null, order: number } | null> };

export type UpdateThemeAsAdminMutationVariables = Exact<{
  themeInput: ThemeInput;
}>;


export type UpdateThemeAsAdminMutation = { __typename?: 'Mutation', updateThemeAsAdmin: { __typename?: 'Theme', id: string, name: string, phrase: string, iconUrl?: string | null, order: number } };

export type GetMyUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUserProfileQuery = { __typename?: 'Query', getMyUserProfile?: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, isAdmin?: boolean | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null, host?: { __typename?: 'Host', id: string, verified: boolean } | null } | null };

export type GetUserProfileQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserProfileQuery = { __typename?: 'Query', getUserProfile?: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, isAdmin?: boolean | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null, host?: { __typename?: 'Host', id: string, verified: boolean } | null } | null };

export type GetAllUsersAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetAllUsersAsAdminQuery = { __typename?: 'Query', getAllUsersAsAdmin: { __typename?: 'UserConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, isAdmin?: boolean | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null, host?: { __typename?: 'Host', id: string, verified: boolean } | null } | null> } };

export type SearchUserQueryVariables = Exact<{
  query: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type SearchUserQuery = { __typename?: 'Query', searchUser: { __typename?: 'UserConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, isAdmin?: boolean | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null, host?: { __typename?: 'Host', id: string, verified: boolean } | null } | null> } };

export type UpdateUserProfileAsAdminMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>;
  userInput: UserInput;
}>;


export type UpdateUserProfileAsAdminMutation = { __typename?: 'Mutation', updateUserProfileAsAdmin: { __typename?: 'UserProfile', id: string, username: string, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, gender?: Gender | null, isAdmin?: boolean | null, createdAt: any, updatedAt: any, nationality?: { __typename?: 'Nationality', id: string, name: string } | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null, host?: { __typename?: 'Host', id: string, verified: boolean } | null } };

export type SetAdminUserAsAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type SetAdminUserAsAdminMutation = { __typename?: 'Mutation', setAdminUserAsAdmin: boolean };

export const PageInfoFieldFragmentDoc = gql`
    fragment PageInfoField on PageInfo {
  hasNextPage
  endCursor
}
    `;
export const CommunityFieldFragmentDoc = gql`
    fragment CommunityField on Community {
  id
  uploader {
    id
    name
  }
  title
  body
  from
  to
  host
  media {
    id
    thumbnailUri
    uri
  }
  externalLink
  createdAt
  updatedAt
}
    `;
export const CommunityThumbnailFieldFragmentDoc = gql`
    fragment CommunityThumbnailField on Community {
  id
  uploader {
    id
    name
  }
  title
  from
  to
  host
  media {
    id
    thumbnailUri
  }
  createdAt
}
    `;
export const QuestionFieldFragmentDoc = gql`
    fragment QuestionField on Question {
  id
  type
  name
  email
  phone
  body
  from
  to
  read
  createdAt
  updatedAt
}
    `;
export const ProviderFieldFragmentDoc = gql`
    fragment ProviderField on Provider {
  id
  type
  name
  registrationNumber
  ownerName
  phone
  email
  registrationMedia {
    id
    uri
  }
  verified
  superUser {
    id
    name
    email
    createdAt
    updatedAt
  }
  avatar {
    id
    uri
  }
  active
  createdAt
  updatedAt
}
    `;
export const JobFieldFragmentDoc = gql`
    fragment JobField on Job {
  id
  name
  iconUrl
  order
}
    `;
export const OccupationFieldFragmentDoc = gql`
    fragment OccupationField on Occupation {
  id
  name
  iconUrl
  order
}
    `;
export const BusinessHourFieldFragmentDoc = gql`
    fragment BusinessHourField on BusinessHour {
  id
  from
  to
  breakTimeFrom
  breakTimeTo
  dayOfWeek
}
    `;
export const PlaceFieldFragmentDoc = gql`
    fragment PlaceField on Place {
  id
  name
  address
  latitude
  longitude
  phone
  active
  field {
    id
    name
    iconUrl
    order
  }
  media {
    id
    uri
  }
  businessHours {
    ...BusinessHourField
  }
  createdAt
  updatedAt
}
    ${BusinessHourFieldFragmentDoc}`;
export const UserProfileFieldFragmentDoc = gql`
    fragment UserProfileField on UserProfile {
  id
  username
  name
  email
  phone
  dateOfBirth
  gender
  nationality {
    id
    name
  }
  avatar {
    id
    uri
  }
  host {
    id
    verified
  }
  isAdmin
  createdAt
  updatedAt
}
    `;
export const FieldFieldFragmentDoc = gql`
    fragment FieldField on Field {
  id
  name
  iconUrl
  order
}
    `;
export const ThemeFieldFragmentDoc = gql`
    fragment ThemeField on Theme {
  id
  name
  phrase
  iconUrl
  order
}
    `;
export const ContentFieldFragmentDoc = gql`
    fragment ContentField on Content {
  id
  title
  place {
    ...PlaceField
  }
  uploader {
    ...UserProfileField
  }
  thumbnail {
    id
    uri
    thumbnailUri
  }
  subtitle
  primaryMedia {
    id
    uri
    thumbnailUri
  }
  body
  viewCount
  readCount
  fields {
    ...FieldField
  }
  themes {
    ...ThemeField
  }
  createdAt
  updatedAt
}
    ${PlaceFieldFragmentDoc}
${UserProfileFieldFragmentDoc}
${FieldFieldFragmentDoc}
${ThemeFieldFragmentDoc}`;
export const ManagementFieldFragmentDoc = gql`
    fragment ManagementField on Management {
  id
  languageCode
  homeThemeCount
  mainTitle
  mainDescription
  companyName
  address
  email
  instagram
  kakao
  copyright
  mainIntroductionTitle
  mainIntroductionSubtitle
  mainIntroduction
  refundPolicy
}
    `;
export const EmailListFieldFragmentDoc = gql`
    fragment EmailListField on EmailList {
  id
  email
  createdAt
  updatedAt
}
    `;
export const CreateMoimFaqFieldFragmentDoc = gql`
    fragment CreateMoimFaqField on CreateMoimFaq {
  id
  question
  answer
  active
  languageCode
  createdAt
  updatedAt
}
    `;
export const GetCommunitiesDocument = gql`
    query GetCommunities($first: Int, $after: String) {
  getCommunities(first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...CommunityThumbnailField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${CommunityThumbnailFieldFragmentDoc}`;

/**
 * __useGetCommunitiesQuery__
 *
 * To run a query within a React component, call `useGetCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunitiesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetCommunitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetCommunitiesQuery, GetCommunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunitiesQuery, GetCommunitiesQueryVariables>(GetCommunitiesDocument, options);
      }
export function useGetCommunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunitiesQuery, GetCommunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunitiesQuery, GetCommunitiesQueryVariables>(GetCommunitiesDocument, options);
        }
export type GetCommunitiesQueryHookResult = ReturnType<typeof useGetCommunitiesQuery>;
export type GetCommunitiesLazyQueryHookResult = ReturnType<typeof useGetCommunitiesLazyQuery>;
export type GetCommunitiesQueryResult = Apollo.QueryResult<GetCommunitiesQuery, GetCommunitiesQueryVariables>;
export const GetCommunityDocument = gql`
    query GetCommunity($communityId: ID!) {
  getCommunity(communityId: $communityId) {
    ...CommunityField
  }
}
    ${CommunityFieldFragmentDoc}`;

/**
 * __useGetCommunityQuery__
 *
 * To run a query within a React component, call `useGetCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetCommunityQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityQuery, GetCommunityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityQuery, GetCommunityQueryVariables>(GetCommunityDocument, options);
      }
export function useGetCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityQuery, GetCommunityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityQuery, GetCommunityQueryVariables>(GetCommunityDocument, options);
        }
export type GetCommunityQueryHookResult = ReturnType<typeof useGetCommunityQuery>;
export type GetCommunityLazyQueryHookResult = ReturnType<typeof useGetCommunityLazyQuery>;
export type GetCommunityQueryResult = Apollo.QueryResult<GetCommunityQuery, GetCommunityQueryVariables>;
export const UpdateCommunityDocument = gql`
    mutation UpdateCommunity($communityInput: CommunityInput!) {
  updateCommunity(communityInput: $communityInput) {
    ...CommunityField
  }
}
    ${CommunityFieldFragmentDoc}`;
export type UpdateCommunityMutationFn = Apollo.MutationFunction<UpdateCommunityMutation, UpdateCommunityMutationVariables>;

/**
 * __useUpdateCommunityMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityMutation, { data, loading, error }] = useUpdateCommunityMutation({
 *   variables: {
 *      communityInput: // value for 'communityInput'
 *   },
 * });
 */
export function useUpdateCommunityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityMutation, UpdateCommunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityMutation, UpdateCommunityMutationVariables>(UpdateCommunityDocument, options);
      }
export type UpdateCommunityMutationHookResult = ReturnType<typeof useUpdateCommunityMutation>;
export type UpdateCommunityMutationResult = Apollo.MutationResult<UpdateCommunityMutation>;
export type UpdateCommunityMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityMutation, UpdateCommunityMutationVariables>;
export const UpdateCommunityMediaDocument = gql`
    mutation UpdateCommunityMedia($communityId: ID!, $media: [MediaInput]!) {
  updateCommunityMedia(communityId: $communityId, media: $media) {
    ...CommunityField
  }
}
    ${CommunityFieldFragmentDoc}`;
export type UpdateCommunityMediaMutationFn = Apollo.MutationFunction<UpdateCommunityMediaMutation, UpdateCommunityMediaMutationVariables>;

/**
 * __useUpdateCommunityMediaMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityMediaMutation, { data, loading, error }] = useUpdateCommunityMediaMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      media: // value for 'media'
 *   },
 * });
 */
export function useUpdateCommunityMediaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityMediaMutation, UpdateCommunityMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityMediaMutation, UpdateCommunityMediaMutationVariables>(UpdateCommunityMediaDocument, options);
      }
export type UpdateCommunityMediaMutationHookResult = ReturnType<typeof useUpdateCommunityMediaMutation>;
export type UpdateCommunityMediaMutationResult = Apollo.MutationResult<UpdateCommunityMediaMutation>;
export type UpdateCommunityMediaMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityMediaMutation, UpdateCommunityMediaMutationVariables>;
export const DeleteCommunityMediaDocument = gql`
    mutation DeleteCommunityMedia($communityId: ID!, $mediaId: ID!) {
  deleteCommunityMedia(communityId: $communityId, mediaId: $mediaId) {
    ...CommunityField
  }
}
    ${CommunityFieldFragmentDoc}`;
export type DeleteCommunityMediaMutationFn = Apollo.MutationFunction<DeleteCommunityMediaMutation, DeleteCommunityMediaMutationVariables>;

/**
 * __useDeleteCommunityMediaMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityMediaMutation, { data, loading, error }] = useDeleteCommunityMediaMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useDeleteCommunityMediaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommunityMediaMutation, DeleteCommunityMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommunityMediaMutation, DeleteCommunityMediaMutationVariables>(DeleteCommunityMediaDocument, options);
      }
export type DeleteCommunityMediaMutationHookResult = ReturnType<typeof useDeleteCommunityMediaMutation>;
export type DeleteCommunityMediaMutationResult = Apollo.MutationResult<DeleteCommunityMediaMutation>;
export type DeleteCommunityMediaMutationOptions = Apollo.BaseMutationOptions<DeleteCommunityMediaMutation, DeleteCommunityMediaMutationVariables>;
export const DeleteCommunityDocument = gql`
    mutation DeleteCommunity($communityId: ID!) {
  deleteCommunity(communityId: $communityId)
}
    `;
export type DeleteCommunityMutationFn = Apollo.MutationFunction<DeleteCommunityMutation, DeleteCommunityMutationVariables>;

/**
 * __useDeleteCommunityMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityMutation, { data, loading, error }] = useDeleteCommunityMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useDeleteCommunityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommunityMutation, DeleteCommunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommunityMutation, DeleteCommunityMutationVariables>(DeleteCommunityDocument, options);
      }
export type DeleteCommunityMutationHookResult = ReturnType<typeof useDeleteCommunityMutation>;
export type DeleteCommunityMutationResult = Apollo.MutationResult<DeleteCommunityMutation>;
export type DeleteCommunityMutationOptions = Apollo.BaseMutationOptions<DeleteCommunityMutation, DeleteCommunityMutationVariables>;
export const UpdateContentAsAdminDocument = gql`
    mutation UpdateContentAsAdmin($contentInput: ContentInput!) {
  updateContentAsAdmin(contentInput: $contentInput) {
    ...ContentField
  }
}
    ${ContentFieldFragmentDoc}`;
export type UpdateContentAsAdminMutationFn = Apollo.MutationFunction<UpdateContentAsAdminMutation, UpdateContentAsAdminMutationVariables>;

/**
 * __useUpdateContentAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateContentAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentAsAdminMutation, { data, loading, error }] = useUpdateContentAsAdminMutation({
 *   variables: {
 *      contentInput: // value for 'contentInput'
 *   },
 * });
 */
export function useUpdateContentAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentAsAdminMutation, UpdateContentAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContentAsAdminMutation, UpdateContentAsAdminMutationVariables>(UpdateContentAsAdminDocument, options);
      }
export type UpdateContentAsAdminMutationHookResult = ReturnType<typeof useUpdateContentAsAdminMutation>;
export type UpdateContentAsAdminMutationResult = Apollo.MutationResult<UpdateContentAsAdminMutation>;
export type UpdateContentAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateContentAsAdminMutation, UpdateContentAsAdminMutationVariables>;
export const DeleteContentDocument = gql`
    mutation DeleteContent($contentId: ID!) {
  deleteContent(contentId: $contentId)
}
    `;
export type DeleteContentMutationFn = Apollo.MutationFunction<DeleteContentMutation, DeleteContentMutationVariables>;

/**
 * __useDeleteContentMutation__
 *
 * To run a mutation, you first call `useDeleteContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentMutation, { data, loading, error }] = useDeleteContentMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useDeleteContentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContentMutation, DeleteContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContentMutation, DeleteContentMutationVariables>(DeleteContentDocument, options);
      }
export type DeleteContentMutationHookResult = ReturnType<typeof useDeleteContentMutation>;
export type DeleteContentMutationResult = Apollo.MutationResult<DeleteContentMutation>;
export type DeleteContentMutationOptions = Apollo.BaseMutationOptions<DeleteContentMutation, DeleteContentMutationVariables>;
export const GetContentsDocument = gql`
    query GetContents($fieldId: ID, $themeId: ID, $sort: ContentSortOption, $first: Int, $after: String) {
  getContents(
    fieldId: $fieldId
    themeId: $themeId
    sort: $sort
    first: $first
    after: $after
  ) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...ContentField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${ContentFieldFragmentDoc}`;

/**
 * __useGetContentsQuery__
 *
 * To run a query within a React component, call `useGetContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentsQuery({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      themeId: // value for 'themeId'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetContentsQuery(baseOptions?: Apollo.QueryHookOptions<GetContentsQuery, GetContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentsQuery, GetContentsQueryVariables>(GetContentsDocument, options);
      }
export function useGetContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentsQuery, GetContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentsQuery, GetContentsQueryVariables>(GetContentsDocument, options);
        }
export type GetContentsQueryHookResult = ReturnType<typeof useGetContentsQuery>;
export type GetContentsLazyQueryHookResult = ReturnType<typeof useGetContentsLazyQuery>;
export type GetContentsQueryResult = Apollo.QueryResult<GetContentsQuery, GetContentsQueryVariables>;
export const GetContentDocument = gql`
    query GetContent($contentId: ID!) {
  getContent(contentId: $contentId) {
    ...ContentField
  }
}
    ${ContentFieldFragmentDoc}`;

/**
 * __useGetContentQuery__
 *
 * To run a query within a React component, call `useGetContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useGetContentQuery(baseOptions: Apollo.QueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
      }
export function useGetContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
        }
export type GetContentQueryHookResult = ReturnType<typeof useGetContentQuery>;
export type GetContentLazyQueryHookResult = ReturnType<typeof useGetContentLazyQuery>;
export type GetContentQueryResult = Apollo.QueryResult<GetContentQuery, GetContentQueryVariables>;
export const SearchContentsDocument = gql`
    query SearchContents($query: String!, $first: Int, $after: String) {
  searchContents(query: $query, first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...ContentField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${ContentFieldFragmentDoc}`;

/**
 * __useSearchContentsQuery__
 *
 * To run a query within a React component, call `useSearchContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchContentsQuery(baseOptions: Apollo.QueryHookOptions<SearchContentsQuery, SearchContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchContentsQuery, SearchContentsQueryVariables>(SearchContentsDocument, options);
      }
export function useSearchContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchContentsQuery, SearchContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchContentsQuery, SearchContentsQueryVariables>(SearchContentsDocument, options);
        }
export type SearchContentsQueryHookResult = ReturnType<typeof useSearchContentsQuery>;
export type SearchContentsLazyQueryHookResult = ReturnType<typeof useSearchContentsLazyQuery>;
export type SearchContentsQueryResult = Apollo.QueryResult<SearchContentsQuery, SearchContentsQueryVariables>;
export const GetContentReadByContentIdAsAdminDocument = gql`
    query GetContentReadByContentIdAsAdmin($contentId: ID!) {
  getContentReadByContentIdAsAdmin(contentId: $contentId) {
    id
    user {
      id
      username
      name
      email
    }
    content {
      id
      title
      readCount
      viewCount
    }
    ipV4Address
    countryCode
    state
    countryName
    city
    latitude
    longitude
  }
}
    `;

/**
 * __useGetContentReadByContentIdAsAdminQuery__
 *
 * To run a query within a React component, call `useGetContentReadByContentIdAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentReadByContentIdAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentReadByContentIdAsAdminQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useGetContentReadByContentIdAsAdminQuery(baseOptions: Apollo.QueryHookOptions<GetContentReadByContentIdAsAdminQuery, GetContentReadByContentIdAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentReadByContentIdAsAdminQuery, GetContentReadByContentIdAsAdminQueryVariables>(GetContentReadByContentIdAsAdminDocument, options);
      }
export function useGetContentReadByContentIdAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentReadByContentIdAsAdminQuery, GetContentReadByContentIdAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentReadByContentIdAsAdminQuery, GetContentReadByContentIdAsAdminQueryVariables>(GetContentReadByContentIdAsAdminDocument, options);
        }
export type GetContentReadByContentIdAsAdminQueryHookResult = ReturnType<typeof useGetContentReadByContentIdAsAdminQuery>;
export type GetContentReadByContentIdAsAdminLazyQueryHookResult = ReturnType<typeof useGetContentReadByContentIdAsAdminLazyQuery>;
export type GetContentReadByContentIdAsAdminQueryResult = Apollo.QueryResult<GetContentReadByContentIdAsAdminQuery, GetContentReadByContentIdAsAdminQueryVariables>;
export const GetEmailListsAsAdminDocument = gql`
    query GetEmailListsAsAdmin($first: Int, $after: String) {
  getEmailListsAsAdmin(first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...EmailListField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${EmailListFieldFragmentDoc}`;

/**
 * __useGetEmailListsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetEmailListsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailListsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailListsAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetEmailListsAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailListsAsAdminQuery, GetEmailListsAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailListsAsAdminQuery, GetEmailListsAsAdminQueryVariables>(GetEmailListsAsAdminDocument, options);
      }
export function useGetEmailListsAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailListsAsAdminQuery, GetEmailListsAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailListsAsAdminQuery, GetEmailListsAsAdminQueryVariables>(GetEmailListsAsAdminDocument, options);
        }
export type GetEmailListsAsAdminQueryHookResult = ReturnType<typeof useGetEmailListsAsAdminQuery>;
export type GetEmailListsAsAdminLazyQueryHookResult = ReturnType<typeof useGetEmailListsAsAdminLazyQuery>;
export type GetEmailListsAsAdminQueryResult = Apollo.QueryResult<GetEmailListsAsAdminQuery, GetEmailListsAsAdminQueryVariables>;
export const GetEmailListsCountAsAdminDocument = gql`
    query GetEmailListsCountAsAdmin {
  getEmailListsCountAsAdmin
}
    `;

/**
 * __useGetEmailListsCountAsAdminQuery__
 *
 * To run a query within a React component, call `useGetEmailListsCountAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailListsCountAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailListsCountAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailListsCountAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailListsCountAsAdminQuery, GetEmailListsCountAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailListsCountAsAdminQuery, GetEmailListsCountAsAdminQueryVariables>(GetEmailListsCountAsAdminDocument, options);
      }
export function useGetEmailListsCountAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailListsCountAsAdminQuery, GetEmailListsCountAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailListsCountAsAdminQuery, GetEmailListsCountAsAdminQueryVariables>(GetEmailListsCountAsAdminDocument, options);
        }
export type GetEmailListsCountAsAdminQueryHookResult = ReturnType<typeof useGetEmailListsCountAsAdminQuery>;
export type GetEmailListsCountAsAdminLazyQueryHookResult = ReturnType<typeof useGetEmailListsCountAsAdminLazyQuery>;
export type GetEmailListsCountAsAdminQueryResult = Apollo.QueryResult<GetEmailListsCountAsAdminQuery, GetEmailListsCountAsAdminQueryVariables>;
export const GetFieldsDocument = gql`
    query GetFields {
  getFields {
    ...FieldField
  }
}
    ${FieldFieldFragmentDoc}`;

/**
 * __useGetFieldsQuery__
 *
 * To run a query within a React component, call `useGetFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFieldsQuery(baseOptions?: Apollo.QueryHookOptions<GetFieldsQuery, GetFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFieldsQuery, GetFieldsQueryVariables>(GetFieldsDocument, options);
      }
export function useGetFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFieldsQuery, GetFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFieldsQuery, GetFieldsQueryVariables>(GetFieldsDocument, options);
        }
export type GetFieldsQueryHookResult = ReturnType<typeof useGetFieldsQuery>;
export type GetFieldsLazyQueryHookResult = ReturnType<typeof useGetFieldsLazyQuery>;
export type GetFieldsQueryResult = Apollo.QueryResult<GetFieldsQuery, GetFieldsQueryVariables>;
export const UpdateFieldAsAdminDocument = gql`
    mutation UpdateFieldAsAdmin($fieldInput: FieldInput!) {
  updateFieldAsAdmin(fieldInput: $fieldInput) {
    ...FieldField
  }
}
    ${FieldFieldFragmentDoc}`;
export type UpdateFieldAsAdminMutationFn = Apollo.MutationFunction<UpdateFieldAsAdminMutation, UpdateFieldAsAdminMutationVariables>;

/**
 * __useUpdateFieldAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateFieldAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldAsAdminMutation, { data, loading, error }] = useUpdateFieldAsAdminMutation({
 *   variables: {
 *      fieldInput: // value for 'fieldInput'
 *   },
 * });
 */
export function useUpdateFieldAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFieldAsAdminMutation, UpdateFieldAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFieldAsAdminMutation, UpdateFieldAsAdminMutationVariables>(UpdateFieldAsAdminDocument, options);
      }
export type UpdateFieldAsAdminMutationHookResult = ReturnType<typeof useUpdateFieldAsAdminMutation>;
export type UpdateFieldAsAdminMutationResult = Apollo.MutationResult<UpdateFieldAsAdminMutation>;
export type UpdateFieldAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateFieldAsAdminMutation, UpdateFieldAsAdminMutationVariables>;
export const GetHostsByVerificationDocument = gql`
    query GetHostsByVerification($verified: Boolean, $first: Int, $after: String) {
  getHostsByVerification(verified: $verified, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      bankName
      bankAccount
      bankAccountMedia {
        id
        uri
      }
      introduction
      verified
      idNumber
      business {
        id
        name
        ownerName
        registrationNumber
        registrationMedia {
          id
          uri
        }
        taxEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        username
        name
        email
        phone
        dateOfBirth
        gender
        nationality {
          id
          name
        }
        avatar {
          id
          uri
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetHostsByVerificationQuery__
 *
 * To run a query within a React component, call `useGetHostsByVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostsByVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostsByVerificationQuery({
 *   variables: {
 *      verified: // value for 'verified'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetHostsByVerificationQuery(baseOptions?: Apollo.QueryHookOptions<GetHostsByVerificationQuery, GetHostsByVerificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHostsByVerificationQuery, GetHostsByVerificationQueryVariables>(GetHostsByVerificationDocument, options);
      }
export function useGetHostsByVerificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHostsByVerificationQuery, GetHostsByVerificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHostsByVerificationQuery, GetHostsByVerificationQueryVariables>(GetHostsByVerificationDocument, options);
        }
export type GetHostsByVerificationQueryHookResult = ReturnType<typeof useGetHostsByVerificationQuery>;
export type GetHostsByVerificationLazyQueryHookResult = ReturnType<typeof useGetHostsByVerificationLazyQuery>;
export type GetHostsByVerificationQueryResult = Apollo.QueryResult<GetHostsByVerificationQuery, GetHostsByVerificationQueryVariables>;
export const ToggleHostVerifiedAsAdminDocument = gql`
    mutation ToggleHostVerifiedAsAdmin($hostId: ID!, $verified: Boolean!) {
  toggleHostVerifiedAsAdmin(hostId: $hostId, verified: $verified) {
    id
    verified
  }
}
    `;
export type ToggleHostVerifiedAsAdminMutationFn = Apollo.MutationFunction<ToggleHostVerifiedAsAdminMutation, ToggleHostVerifiedAsAdminMutationVariables>;

/**
 * __useToggleHostVerifiedAsAdminMutation__
 *
 * To run a mutation, you first call `useToggleHostVerifiedAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleHostVerifiedAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleHostVerifiedAsAdminMutation, { data, loading, error }] = useToggleHostVerifiedAsAdminMutation({
 *   variables: {
 *      hostId: // value for 'hostId'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useToggleHostVerifiedAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<ToggleHostVerifiedAsAdminMutation, ToggleHostVerifiedAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleHostVerifiedAsAdminMutation, ToggleHostVerifiedAsAdminMutationVariables>(ToggleHostVerifiedAsAdminDocument, options);
      }
export type ToggleHostVerifiedAsAdminMutationHookResult = ReturnType<typeof useToggleHostVerifiedAsAdminMutation>;
export type ToggleHostVerifiedAsAdminMutationResult = Apollo.MutationResult<ToggleHostVerifiedAsAdminMutation>;
export type ToggleHostVerifiedAsAdminMutationOptions = Apollo.BaseMutationOptions<ToggleHostVerifiedAsAdminMutation, ToggleHostVerifiedAsAdminMutationVariables>;
export const GetJobsDocument = gql`
    query GetJobs {
  getJobs {
    ...JobField
  }
}
    ${JobFieldFragmentDoc}`;

/**
 * __useGetJobsQuery__
 *
 * To run a query within a React component, call `useGetJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
      }
export function useGetJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
        }
export type GetJobsQueryHookResult = ReturnType<typeof useGetJobsQuery>;
export type GetJobsLazyQueryHookResult = ReturnType<typeof useGetJobsLazyQuery>;
export type GetJobsQueryResult = Apollo.QueryResult<GetJobsQuery, GetJobsQueryVariables>;
export const UpdateJobAsAdminDocument = gql`
    mutation UpdateJobAsAdmin($jobInput: JobInput!) {
  updateJobAsAdmin(jobInput: $jobInput) {
    ...JobField
  }
}
    ${JobFieldFragmentDoc}`;
export type UpdateJobAsAdminMutationFn = Apollo.MutationFunction<UpdateJobAsAdminMutation, UpdateJobAsAdminMutationVariables>;

/**
 * __useUpdateJobAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateJobAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobAsAdminMutation, { data, loading, error }] = useUpdateJobAsAdminMutation({
 *   variables: {
 *      jobInput: // value for 'jobInput'
 *   },
 * });
 */
export function useUpdateJobAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobAsAdminMutation, UpdateJobAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobAsAdminMutation, UpdateJobAsAdminMutationVariables>(UpdateJobAsAdminDocument, options);
      }
export type UpdateJobAsAdminMutationHookResult = ReturnType<typeof useUpdateJobAsAdminMutation>;
export type UpdateJobAsAdminMutationResult = Apollo.MutationResult<UpdateJobAsAdminMutation>;
export type UpdateJobAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateJobAsAdminMutation, UpdateJobAsAdminMutationVariables>;
export const UpdateManagementAsAdminDocument = gql`
    mutation UpdateManagementAsAdmin($managementInput: ManagementInput!) {
  updateManagementAsAdmin(managementInput: $managementInput) {
    ...ManagementField
  }
}
    ${ManagementFieldFragmentDoc}`;
export type UpdateManagementAsAdminMutationFn = Apollo.MutationFunction<UpdateManagementAsAdminMutation, UpdateManagementAsAdminMutationVariables>;

/**
 * __useUpdateManagementAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateManagementAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagementAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagementAsAdminMutation, { data, loading, error }] = useUpdateManagementAsAdminMutation({
 *   variables: {
 *      managementInput: // value for 'managementInput'
 *   },
 * });
 */
export function useUpdateManagementAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManagementAsAdminMutation, UpdateManagementAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManagementAsAdminMutation, UpdateManagementAsAdminMutationVariables>(UpdateManagementAsAdminDocument, options);
      }
export type UpdateManagementAsAdminMutationHookResult = ReturnType<typeof useUpdateManagementAsAdminMutation>;
export type UpdateManagementAsAdminMutationResult = Apollo.MutationResult<UpdateManagementAsAdminMutation>;
export type UpdateManagementAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateManagementAsAdminMutation, UpdateManagementAsAdminMutationVariables>;
export const GetManagementsAsAdminDocument = gql`
    query GetManagementsAsAdmin {
  getManagementsAsAdmin {
    ...ManagementField
  }
}
    ${ManagementFieldFragmentDoc}`;

/**
 * __useGetManagementsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetManagementsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementsAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagementsAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetManagementsAsAdminQuery, GetManagementsAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManagementsAsAdminQuery, GetManagementsAsAdminQueryVariables>(GetManagementsAsAdminDocument, options);
      }
export function useGetManagementsAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagementsAsAdminQuery, GetManagementsAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManagementsAsAdminQuery, GetManagementsAsAdminQueryVariables>(GetManagementsAsAdminDocument, options);
        }
export type GetManagementsAsAdminQueryHookResult = ReturnType<typeof useGetManagementsAsAdminQuery>;
export type GetManagementsAsAdminLazyQueryHookResult = ReturnType<typeof useGetManagementsAsAdminLazyQuery>;
export type GetManagementsAsAdminQueryResult = Apollo.QueryResult<GetManagementsAsAdminQuery, GetManagementsAsAdminQueryVariables>;
export const GetCreateMoimFaqsAsAdminDocument = gql`
    query GetCreateMoimFaqsAsAdmin {
  getCreateMoimFaqsAsAdmin {
    ...CreateMoimFaqField
  }
}
    ${CreateMoimFaqFieldFragmentDoc}`;

/**
 * __useGetCreateMoimFaqsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetCreateMoimFaqsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreateMoimFaqsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreateMoimFaqsAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCreateMoimFaqsAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetCreateMoimFaqsAsAdminQuery, GetCreateMoimFaqsAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreateMoimFaqsAsAdminQuery, GetCreateMoimFaqsAsAdminQueryVariables>(GetCreateMoimFaqsAsAdminDocument, options);
      }
export function useGetCreateMoimFaqsAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreateMoimFaqsAsAdminQuery, GetCreateMoimFaqsAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreateMoimFaqsAsAdminQuery, GetCreateMoimFaqsAsAdminQueryVariables>(GetCreateMoimFaqsAsAdminDocument, options);
        }
export type GetCreateMoimFaqsAsAdminQueryHookResult = ReturnType<typeof useGetCreateMoimFaqsAsAdminQuery>;
export type GetCreateMoimFaqsAsAdminLazyQueryHookResult = ReturnType<typeof useGetCreateMoimFaqsAsAdminLazyQuery>;
export type GetCreateMoimFaqsAsAdminQueryResult = Apollo.QueryResult<GetCreateMoimFaqsAsAdminQuery, GetCreateMoimFaqsAsAdminQueryVariables>;
export const UpdateCreateMoimFaqAsAdminDocument = gql`
    mutation UpdateCreateMoimFaqAsAdmin($createMoimFaqInput: CreateMoimFaqInput!) {
  updateCreateMoimFaqAsAdmin(createMoimFaqInput: $createMoimFaqInput) {
    ...CreateMoimFaqField
  }
}
    ${CreateMoimFaqFieldFragmentDoc}`;
export type UpdateCreateMoimFaqAsAdminMutationFn = Apollo.MutationFunction<UpdateCreateMoimFaqAsAdminMutation, UpdateCreateMoimFaqAsAdminMutationVariables>;

/**
 * __useUpdateCreateMoimFaqAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateCreateMoimFaqAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreateMoimFaqAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreateMoimFaqAsAdminMutation, { data, loading, error }] = useUpdateCreateMoimFaqAsAdminMutation({
 *   variables: {
 *      createMoimFaqInput: // value for 'createMoimFaqInput'
 *   },
 * });
 */
export function useUpdateCreateMoimFaqAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCreateMoimFaqAsAdminMutation, UpdateCreateMoimFaqAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCreateMoimFaqAsAdminMutation, UpdateCreateMoimFaqAsAdminMutationVariables>(UpdateCreateMoimFaqAsAdminDocument, options);
      }
export type UpdateCreateMoimFaqAsAdminMutationHookResult = ReturnType<typeof useUpdateCreateMoimFaqAsAdminMutation>;
export type UpdateCreateMoimFaqAsAdminMutationResult = Apollo.MutationResult<UpdateCreateMoimFaqAsAdminMutation>;
export type UpdateCreateMoimFaqAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateCreateMoimFaqAsAdminMutation, UpdateCreateMoimFaqAsAdminMutationVariables>;
export const GetMoimsByStatusAsAdminDocument = gql`
    query GetMoimsByStatusAsAdmin($status: MoimStatus, $first: Int, $after: String) {
  getMoimsByStatusAsAdmin(status: $status, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      host {
        id
        bankName
        bankAccount
        bankAccountMedia {
          id
          uri
        }
        introduction
        verified
        idNumber
        business {
          id
          name
          ownerName
          registrationNumber
          registrationMedia {
            id
            uri
          }
          taxEmail
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        user {
          id
          username
          name
          email
          phone
          dateOfBirth
          gender
          nationality {
            id
            name
          }
          avatar {
            id
            uri
          }
          createdAt
          updatedAt
        }
      }
      place {
        id
        name
        address
        addressDetail
        isPrivate
      }
      moimContents {
        id
        languageCode
        title
        introduction
        detail
        price
        createdAt
        updatedAt
      }
      moimStartAt
      moimEndAt
      maxPersons
      minPersons
      genderRestriction
      minAge
      maxAge
      status
      message
      coverPhoto {
        id
        uri
      }
      media {
        id
        uri
      }
      viewCount
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetMoimsByStatusAsAdminQuery__
 *
 * To run a query within a React component, call `useGetMoimsByStatusAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoimsByStatusAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoimsByStatusAsAdminQuery({
 *   variables: {
 *      status: // value for 'status'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetMoimsByStatusAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetMoimsByStatusAsAdminQuery, GetMoimsByStatusAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoimsByStatusAsAdminQuery, GetMoimsByStatusAsAdminQueryVariables>(GetMoimsByStatusAsAdminDocument, options);
      }
export function useGetMoimsByStatusAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoimsByStatusAsAdminQuery, GetMoimsByStatusAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoimsByStatusAsAdminQuery, GetMoimsByStatusAsAdminQueryVariables>(GetMoimsByStatusAsAdminDocument, options);
        }
export type GetMoimsByStatusAsAdminQueryHookResult = ReturnType<typeof useGetMoimsByStatusAsAdminQuery>;
export type GetMoimsByStatusAsAdminLazyQueryHookResult = ReturnType<typeof useGetMoimsByStatusAsAdminLazyQuery>;
export type GetMoimsByStatusAsAdminQueryResult = Apollo.QueryResult<GetMoimsByStatusAsAdminQuery, GetMoimsByStatusAsAdminQueryVariables>;
export const GetMoimDocument = gql`
    query GetMoim($moimId: ID!) {
  getMoim(moimId: $moimId) {
    id
    host {
      id
      bankName
      bankAccount
      bankAccountMedia {
        id
        uri
      }
      introduction
      verified
      idNumber
      business {
        id
        name
        ownerName
        registrationNumber
        registrationMedia {
          id
          uri
        }
        taxEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        username
        name
        email
        phone
        dateOfBirth
        gender
        nationality {
          id
          name
        }
        avatar {
          id
          uri
        }
        createdAt
        updatedAt
      }
    }
    place {
      id
      name
      address
      addressDetail
      isPrivate
    }
    moimContents {
      id
      languageCode
      title
      introduction
      detail
      price
      createdAt
      updatedAt
    }
    moimStartAt
    moimEndAt
    maxPersons
    minPersons
    genderRestriction
    minAge
    maxAge
    status
    message
    coverPhoto {
      id
      uri
    }
    media {
      id
      uri
    }
    viewCount
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetMoimQuery__
 *
 * To run a query within a React component, call `useGetMoimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoimQuery({
 *   variables: {
 *      moimId: // value for 'moimId'
 *   },
 * });
 */
export function useGetMoimQuery(baseOptions: Apollo.QueryHookOptions<GetMoimQuery, GetMoimQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoimQuery, GetMoimQueryVariables>(GetMoimDocument, options);
      }
export function useGetMoimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoimQuery, GetMoimQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoimQuery, GetMoimQueryVariables>(GetMoimDocument, options);
        }
export type GetMoimQueryHookResult = ReturnType<typeof useGetMoimQuery>;
export type GetMoimLazyQueryHookResult = ReturnType<typeof useGetMoimLazyQuery>;
export type GetMoimQueryResult = Apollo.QueryResult<GetMoimQuery, GetMoimQueryVariables>;
export const UpdateMoimContentAsAdminDocument = gql`
    mutation UpdateMoimContentAsAdmin($adminMoimContentInput: AdminMoimContentInput) {
  updateMoimContentAsAdmin(adminMoimContentInput: $adminMoimContentInput) {
    id
    moimContents {
      id
      languageCode
      title
      introduction
      detail
      price
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdateMoimContentAsAdminMutationFn = Apollo.MutationFunction<UpdateMoimContentAsAdminMutation, UpdateMoimContentAsAdminMutationVariables>;

/**
 * __useUpdateMoimContentAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateMoimContentAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMoimContentAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMoimContentAsAdminMutation, { data, loading, error }] = useUpdateMoimContentAsAdminMutation({
 *   variables: {
 *      adminMoimContentInput: // value for 'adminMoimContentInput'
 *   },
 * });
 */
export function useUpdateMoimContentAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMoimContentAsAdminMutation, UpdateMoimContentAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMoimContentAsAdminMutation, UpdateMoimContentAsAdminMutationVariables>(UpdateMoimContentAsAdminDocument, options);
      }
export type UpdateMoimContentAsAdminMutationHookResult = ReturnType<typeof useUpdateMoimContentAsAdminMutation>;
export type UpdateMoimContentAsAdminMutationResult = Apollo.MutationResult<UpdateMoimContentAsAdminMutation>;
export type UpdateMoimContentAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateMoimContentAsAdminMutation, UpdateMoimContentAsAdminMutationVariables>;
export const SetMoimStatusAsAdminDocument = gql`
    mutation SetMoimStatusAsAdmin($moimId: ID!, $status: MoimStatus!, $message: String) {
  setMoimStatusAsAdmin(moimId: $moimId, status: $status, message: $message) {
    id
    status
    message
  }
}
    `;
export type SetMoimStatusAsAdminMutationFn = Apollo.MutationFunction<SetMoimStatusAsAdminMutation, SetMoimStatusAsAdminMutationVariables>;

/**
 * __useSetMoimStatusAsAdminMutation__
 *
 * To run a mutation, you first call `useSetMoimStatusAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMoimStatusAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMoimStatusAsAdminMutation, { data, loading, error }] = useSetMoimStatusAsAdminMutation({
 *   variables: {
 *      moimId: // value for 'moimId'
 *      status: // value for 'status'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSetMoimStatusAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<SetMoimStatusAsAdminMutation, SetMoimStatusAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMoimStatusAsAdminMutation, SetMoimStatusAsAdminMutationVariables>(SetMoimStatusAsAdminDocument, options);
      }
export type SetMoimStatusAsAdminMutationHookResult = ReturnType<typeof useSetMoimStatusAsAdminMutation>;
export type SetMoimStatusAsAdminMutationResult = Apollo.MutationResult<SetMoimStatusAsAdminMutation>;
export type SetMoimStatusAsAdminMutationOptions = Apollo.BaseMutationOptions<SetMoimStatusAsAdminMutation, SetMoimStatusAsAdminMutationVariables>;
export const GetOccupationsDocument = gql`
    query GetOccupations {
  getOccupations {
    ...OccupationField
  }
}
    ${OccupationFieldFragmentDoc}`;

/**
 * __useGetOccupationsQuery__
 *
 * To run a query within a React component, call `useGetOccupationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOccupationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOccupationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOccupationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOccupationsQuery, GetOccupationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOccupationsQuery, GetOccupationsQueryVariables>(GetOccupationsDocument, options);
      }
export function useGetOccupationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOccupationsQuery, GetOccupationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOccupationsQuery, GetOccupationsQueryVariables>(GetOccupationsDocument, options);
        }
export type GetOccupationsQueryHookResult = ReturnType<typeof useGetOccupationsQuery>;
export type GetOccupationsLazyQueryHookResult = ReturnType<typeof useGetOccupationsLazyQuery>;
export type GetOccupationsQueryResult = Apollo.QueryResult<GetOccupationsQuery, GetOccupationsQueryVariables>;
export const UpdateOccupationAsAdminDocument = gql`
    mutation UpdateOccupationAsAdmin($occupationInput: OccupationInput!) {
  updateOccupationAsAdmin(occupationInput: $occupationInput) {
    ...OccupationField
  }
}
    ${OccupationFieldFragmentDoc}`;
export type UpdateOccupationAsAdminMutationFn = Apollo.MutationFunction<UpdateOccupationAsAdminMutation, UpdateOccupationAsAdminMutationVariables>;

/**
 * __useUpdateOccupationAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateOccupationAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOccupationAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOccupationAsAdminMutation, { data, loading, error }] = useUpdateOccupationAsAdminMutation({
 *   variables: {
 *      occupationInput: // value for 'occupationInput'
 *   },
 * });
 */
export function useUpdateOccupationAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOccupationAsAdminMutation, UpdateOccupationAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOccupationAsAdminMutation, UpdateOccupationAsAdminMutationVariables>(UpdateOccupationAsAdminDocument, options);
      }
export type UpdateOccupationAsAdminMutationHookResult = ReturnType<typeof useUpdateOccupationAsAdminMutation>;
export type UpdateOccupationAsAdminMutationResult = Apollo.MutationResult<UpdateOccupationAsAdminMutation>;
export type UpdateOccupationAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateOccupationAsAdminMutation, UpdateOccupationAsAdminMutationVariables>;
export const CreatePlaceDocument = gql`
    mutation CreatePlace($placeInput: PlaceInput!) {
  createPlace(placeInput: $placeInput) {
    ...PlaceField
  }
}
    ${PlaceFieldFragmentDoc}`;
export type CreatePlaceMutationFn = Apollo.MutationFunction<CreatePlaceMutation, CreatePlaceMutationVariables>;

/**
 * __useCreatePlaceMutation__
 *
 * To run a mutation, you first call `useCreatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceMutation, { data, loading, error }] = useCreatePlaceMutation({
 *   variables: {
 *      placeInput: // value for 'placeInput'
 *   },
 * });
 */
export function useCreatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaceMutation, CreatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaceMutation, CreatePlaceMutationVariables>(CreatePlaceDocument, options);
      }
export type CreatePlaceMutationHookResult = ReturnType<typeof useCreatePlaceMutation>;
export type CreatePlaceMutationResult = Apollo.MutationResult<CreatePlaceMutation>;
export type CreatePlaceMutationOptions = Apollo.BaseMutationOptions<CreatePlaceMutation, CreatePlaceMutationVariables>;
export const UpdatePlaceDocument = gql`
    mutation UpdatePlace($placeInput: PlaceInput!) {
  updatePlace(placeInput: $placeInput) {
    ...PlaceField
  }
}
    ${PlaceFieldFragmentDoc}`;
export type UpdatePlaceMutationFn = Apollo.MutationFunction<UpdatePlaceMutation, UpdatePlaceMutationVariables>;

/**
 * __useUpdatePlaceMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceMutation, { data, loading, error }] = useUpdatePlaceMutation({
 *   variables: {
 *      placeInput: // value for 'placeInput'
 *   },
 * });
 */
export function useUpdatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlaceMutation, UpdatePlaceMutationVariables>(UpdatePlaceDocument, options);
      }
export type UpdatePlaceMutationHookResult = ReturnType<typeof useUpdatePlaceMutation>;
export type UpdatePlaceMutationResult = Apollo.MutationResult<UpdatePlaceMutation>;
export type UpdatePlaceMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>;
export const AddPlaceMediaDocument = gql`
    mutation AddPlaceMedia($placeId: ID!, $placeMediaInput: [PlaceMediaInput]!) {
  addPlaceMedia(placeId: $placeId, placeMediaInput: $placeMediaInput) {
    ...PlaceField
  }
}
    ${PlaceFieldFragmentDoc}`;
export type AddPlaceMediaMutationFn = Apollo.MutationFunction<AddPlaceMediaMutation, AddPlaceMediaMutationVariables>;

/**
 * __useAddPlaceMediaMutation__
 *
 * To run a mutation, you first call `useAddPlaceMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlaceMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlaceMediaMutation, { data, loading, error }] = useAddPlaceMediaMutation({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      placeMediaInput: // value for 'placeMediaInput'
 *   },
 * });
 */
export function useAddPlaceMediaMutation(baseOptions?: Apollo.MutationHookOptions<AddPlaceMediaMutation, AddPlaceMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlaceMediaMutation, AddPlaceMediaMutationVariables>(AddPlaceMediaDocument, options);
      }
export type AddPlaceMediaMutationHookResult = ReturnType<typeof useAddPlaceMediaMutation>;
export type AddPlaceMediaMutationResult = Apollo.MutationResult<AddPlaceMediaMutation>;
export type AddPlaceMediaMutationOptions = Apollo.BaseMutationOptions<AddPlaceMediaMutation, AddPlaceMediaMutationVariables>;
export const DeletePlaceMediaDocument = gql`
    mutation DeletePlaceMedia($placeId: ID!, $mediaId: ID!) {
  deletePlaceMedia(placeId: $placeId, mediaId: $mediaId) {
    ...PlaceField
  }
}
    ${PlaceFieldFragmentDoc}`;
export type DeletePlaceMediaMutationFn = Apollo.MutationFunction<DeletePlaceMediaMutation, DeletePlaceMediaMutationVariables>;

/**
 * __useDeletePlaceMediaMutation__
 *
 * To run a mutation, you first call `useDeletePlaceMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceMediaMutation, { data, loading, error }] = useDeletePlaceMediaMutation({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useDeletePlaceMediaMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceMediaMutation, DeletePlaceMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlaceMediaMutation, DeletePlaceMediaMutationVariables>(DeletePlaceMediaDocument, options);
      }
export type DeletePlaceMediaMutationHookResult = ReturnType<typeof useDeletePlaceMediaMutation>;
export type DeletePlaceMediaMutationResult = Apollo.MutationResult<DeletePlaceMediaMutation>;
export type DeletePlaceMediaMutationOptions = Apollo.BaseMutationOptions<DeletePlaceMediaMutation, DeletePlaceMediaMutationVariables>;
export const GetPlaceDocument = gql`
    query GetPlace($placeId: ID!) {
  getPlace(placeId: $placeId) {
    ...PlaceField
  }
}
    ${PlaceFieldFragmentDoc}`;

/**
 * __useGetPlaceQuery__
 *
 * To run a query within a React component, call `useGetPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetPlaceQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceQuery, GetPlaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlaceQuery, GetPlaceQueryVariables>(GetPlaceDocument, options);
      }
export function useGetPlaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceQuery, GetPlaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlaceQuery, GetPlaceQueryVariables>(GetPlaceDocument, options);
        }
export type GetPlaceQueryHookResult = ReturnType<typeof useGetPlaceQuery>;
export type GetPlaceLazyQueryHookResult = ReturnType<typeof useGetPlaceLazyQuery>;
export type GetPlaceQueryResult = Apollo.QueryResult<GetPlaceQuery, GetPlaceQueryVariables>;
export const GetPlacesDocument = gql`
    query GetPlaces($first: Int, $after: String) {
  getPlaces(first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...PlaceField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${PlaceFieldFragmentDoc}`;

/**
 * __useGetPlacesQuery__
 *
 * To run a query within a React component, call `useGetPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlacesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetPlacesQuery(baseOptions?: Apollo.QueryHookOptions<GetPlacesQuery, GetPlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlacesQuery, GetPlacesQueryVariables>(GetPlacesDocument, options);
      }
export function useGetPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlacesQuery, GetPlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlacesQuery, GetPlacesQueryVariables>(GetPlacesDocument, options);
        }
export type GetPlacesQueryHookResult = ReturnType<typeof useGetPlacesQuery>;
export type GetPlacesLazyQueryHookResult = ReturnType<typeof useGetPlacesLazyQuery>;
export type GetPlacesQueryResult = Apollo.QueryResult<GetPlacesQuery, GetPlacesQueryVariables>;
export const SearchPlacesDocument = gql`
    query SearchPlaces($query: String!, $first: Int, $after: String) {
  searchPlaces(query: $query, first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...PlaceField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${PlaceFieldFragmentDoc}`;

/**
 * __useSearchPlacesQuery__
 *
 * To run a query within a React component, call `useSearchPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPlacesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchPlacesQuery(baseOptions: Apollo.QueryHookOptions<SearchPlacesQuery, SearchPlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPlacesQuery, SearchPlacesQueryVariables>(SearchPlacesDocument, options);
      }
export function useSearchPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPlacesQuery, SearchPlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPlacesQuery, SearchPlacesQueryVariables>(SearchPlacesDocument, options);
        }
export type SearchPlacesQueryHookResult = ReturnType<typeof useSearchPlacesQuery>;
export type SearchPlacesLazyQueryHookResult = ReturnType<typeof useSearchPlacesLazyQuery>;
export type SearchPlacesQueryResult = Apollo.QueryResult<SearchPlacesQuery, SearchPlacesQueryVariables>;
export const SearchProviderDocument = gql`
    query SearchProvider($query: String!, $first: Int, $after: String) {
  searchProvider(query: $query, first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...ProviderField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${ProviderFieldFragmentDoc}`;

/**
 * __useSearchProviderQuery__
 *
 * To run a query within a React component, call `useSearchProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProviderQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchProviderQuery(baseOptions: Apollo.QueryHookOptions<SearchProviderQuery, SearchProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProviderQuery, SearchProviderQueryVariables>(SearchProviderDocument, options);
      }
export function useSearchProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProviderQuery, SearchProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProviderQuery, SearchProviderQueryVariables>(SearchProviderDocument, options);
        }
export type SearchProviderQueryHookResult = ReturnType<typeof useSearchProviderQuery>;
export type SearchProviderLazyQueryHookResult = ReturnType<typeof useSearchProviderLazyQuery>;
export type SearchProviderQueryResult = Apollo.QueryResult<SearchProviderQuery, SearchProviderQueryVariables>;
export const GetProviderTypesDocument = gql`
    query GetProviderTypes {
  getProviderTypes
}
    `;

/**
 * __useGetProviderTypesQuery__
 *
 * To run a query within a React component, call `useGetProviderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProviderTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetProviderTypesQuery, GetProviderTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderTypesQuery, GetProviderTypesQueryVariables>(GetProviderTypesDocument, options);
      }
export function useGetProviderTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderTypesQuery, GetProviderTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderTypesQuery, GetProviderTypesQueryVariables>(GetProviderTypesDocument, options);
        }
export type GetProviderTypesQueryHookResult = ReturnType<typeof useGetProviderTypesQuery>;
export type GetProviderTypesLazyQueryHookResult = ReturnType<typeof useGetProviderTypesLazyQuery>;
export type GetProviderTypesQueryResult = Apollo.QueryResult<GetProviderTypesQuery, GetProviderTypesQueryVariables>;
export const UpdateProviderAsAdminDocument = gql`
    mutation UpdateProviderAsAdmin($providerInput: ProviderInput!) {
  updateProviderAsAdmin(providerInput: $providerInput) {
    ...ProviderField
  }
}
    ${ProviderFieldFragmentDoc}`;
export type UpdateProviderAsAdminMutationFn = Apollo.MutationFunction<UpdateProviderAsAdminMutation, UpdateProviderAsAdminMutationVariables>;

/**
 * __useUpdateProviderAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateProviderAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderAsAdminMutation, { data, loading, error }] = useUpdateProviderAsAdminMutation({
 *   variables: {
 *      providerInput: // value for 'providerInput'
 *   },
 * });
 */
export function useUpdateProviderAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProviderAsAdminMutation, UpdateProviderAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProviderAsAdminMutation, UpdateProviderAsAdminMutationVariables>(UpdateProviderAsAdminDocument, options);
      }
export type UpdateProviderAsAdminMutationHookResult = ReturnType<typeof useUpdateProviderAsAdminMutation>;
export type UpdateProviderAsAdminMutationResult = Apollo.MutationResult<UpdateProviderAsAdminMutation>;
export type UpdateProviderAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateProviderAsAdminMutation, UpdateProviderAsAdminMutationVariables>;
export const GetProviderDocument = gql`
    query GetProvider($providerId: ID!) {
  getProvider(providerId: $providerId) {
    ...ProviderField
  }
}
    ${ProviderFieldFragmentDoc}`;

/**
 * __useGetProviderQuery__
 *
 * To run a query within a React component, call `useGetProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetProviderQuery(baseOptions: Apollo.QueryHookOptions<GetProviderQuery, GetProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderQuery, GetProviderQueryVariables>(GetProviderDocument, options);
      }
export function useGetProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderQuery, GetProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderQuery, GetProviderQueryVariables>(GetProviderDocument, options);
        }
export type GetProviderQueryHookResult = ReturnType<typeof useGetProviderQuery>;
export type GetProviderLazyQueryHookResult = ReturnType<typeof useGetProviderLazyQuery>;
export type GetProviderQueryResult = Apollo.QueryResult<GetProviderQuery, GetProviderQueryVariables>;
export const GetProvidersByTypeDocument = gql`
    query GetProvidersByType($type: ProviderType, $first: Int, $after: String) {
  getProvidersByType(type: $type, first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...ProviderField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${ProviderFieldFragmentDoc}`;

/**
 * __useGetProvidersByTypeQuery__
 *
 * To run a query within a React component, call `useGetProvidersByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvidersByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvidersByTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetProvidersByTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetProvidersByTypeQuery, GetProvidersByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProvidersByTypeQuery, GetProvidersByTypeQueryVariables>(GetProvidersByTypeDocument, options);
      }
export function useGetProvidersByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProvidersByTypeQuery, GetProvidersByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProvidersByTypeQuery, GetProvidersByTypeQueryVariables>(GetProvidersByTypeDocument, options);
        }
export type GetProvidersByTypeQueryHookResult = ReturnType<typeof useGetProvidersByTypeQuery>;
export type GetProvidersByTypeLazyQueryHookResult = ReturnType<typeof useGetProvidersByTypeLazyQuery>;
export type GetProvidersByTypeQueryResult = Apollo.QueryResult<GetProvidersByTypeQuery, GetProvidersByTypeQueryVariables>;
export const VerifyProviderAsAdminDocument = gql`
    mutation VerifyProviderAsAdmin($providerId: ID!, $verified: Boolean!) {
  verifyProviderAsAdmin(providerId: $providerId, verified: $verified) {
    id
    verified
  }
}
    `;
export type VerifyProviderAsAdminMutationFn = Apollo.MutationFunction<VerifyProviderAsAdminMutation, VerifyProviderAsAdminMutationVariables>;

/**
 * __useVerifyProviderAsAdminMutation__
 *
 * To run a mutation, you first call `useVerifyProviderAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyProviderAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyProviderAsAdminMutation, { data, loading, error }] = useVerifyProviderAsAdminMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useVerifyProviderAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<VerifyProviderAsAdminMutation, VerifyProviderAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyProviderAsAdminMutation, VerifyProviderAsAdminMutationVariables>(VerifyProviderAsAdminDocument, options);
      }
export type VerifyProviderAsAdminMutationHookResult = ReturnType<typeof useVerifyProviderAsAdminMutation>;
export type VerifyProviderAsAdminMutationResult = Apollo.MutationResult<VerifyProviderAsAdminMutation>;
export type VerifyProviderAsAdminMutationOptions = Apollo.BaseMutationOptions<VerifyProviderAsAdminMutation, VerifyProviderAsAdminMutationVariables>;
export const ToggleProviderActiveAsAdminDocument = gql`
    mutation ToggleProviderActiveAsAdmin($providerId: ID!, $active: Boolean!) {
  toggleProviderActiveAsAdmin(providerId: $providerId, active: $active) {
    id
    active
  }
}
    `;
export type ToggleProviderActiveAsAdminMutationFn = Apollo.MutationFunction<ToggleProviderActiveAsAdminMutation, ToggleProviderActiveAsAdminMutationVariables>;

/**
 * __useToggleProviderActiveAsAdminMutation__
 *
 * To run a mutation, you first call `useToggleProviderActiveAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleProviderActiveAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleProviderActiveAsAdminMutation, { data, loading, error }] = useToggleProviderActiveAsAdminMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useToggleProviderActiveAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<ToggleProviderActiveAsAdminMutation, ToggleProviderActiveAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleProviderActiveAsAdminMutation, ToggleProviderActiveAsAdminMutationVariables>(ToggleProviderActiveAsAdminDocument, options);
      }
export type ToggleProviderActiveAsAdminMutationHookResult = ReturnType<typeof useToggleProviderActiveAsAdminMutation>;
export type ToggleProviderActiveAsAdminMutationResult = Apollo.MutationResult<ToggleProviderActiveAsAdminMutation>;
export type ToggleProviderActiveAsAdminMutationOptions = Apollo.BaseMutationOptions<ToggleProviderActiveAsAdminMutation, ToggleProviderActiveAsAdminMutationVariables>;
export const GetQuestionsAsAdminDocument = gql`
    query GetQuestionsAsAdmin($type: QuestionType, $first: Int, $after: String) {
  getQuestionsAsAdmin(type: $type, first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...QuestionField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${QuestionFieldFragmentDoc}`;

/**
 * __useGetQuestionsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetQuestionsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsAsAdminQuery({
 *   variables: {
 *      type: // value for 'type'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetQuestionsAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionsAsAdminQuery, GetQuestionsAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionsAsAdminQuery, GetQuestionsAsAdminQueryVariables>(GetQuestionsAsAdminDocument, options);
      }
export function useGetQuestionsAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionsAsAdminQuery, GetQuestionsAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionsAsAdminQuery, GetQuestionsAsAdminQueryVariables>(GetQuestionsAsAdminDocument, options);
        }
export type GetQuestionsAsAdminQueryHookResult = ReturnType<typeof useGetQuestionsAsAdminQuery>;
export type GetQuestionsAsAdminLazyQueryHookResult = ReturnType<typeof useGetQuestionsAsAdminLazyQuery>;
export type GetQuestionsAsAdminQueryResult = Apollo.QueryResult<GetQuestionsAsAdminQuery, GetQuestionsAsAdminQueryVariables>;
export const ToggleQuestionReadAsAdminDocument = gql`
    mutation ToggleQuestionReadAsAdmin($questionId: ID!, $read: Boolean!) {
  toggleQuestionReadAsAdmin(questionId: $questionId, read: $read) {
    id
    read
  }
}
    `;
export type ToggleQuestionReadAsAdminMutationFn = Apollo.MutationFunction<ToggleQuestionReadAsAdminMutation, ToggleQuestionReadAsAdminMutationVariables>;

/**
 * __useToggleQuestionReadAsAdminMutation__
 *
 * To run a mutation, you first call `useToggleQuestionReadAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleQuestionReadAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleQuestionReadAsAdminMutation, { data, loading, error }] = useToggleQuestionReadAsAdminMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      read: // value for 'read'
 *   },
 * });
 */
export function useToggleQuestionReadAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<ToggleQuestionReadAsAdminMutation, ToggleQuestionReadAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleQuestionReadAsAdminMutation, ToggleQuestionReadAsAdminMutationVariables>(ToggleQuestionReadAsAdminDocument, options);
      }
export type ToggleQuestionReadAsAdminMutationHookResult = ReturnType<typeof useToggleQuestionReadAsAdminMutation>;
export type ToggleQuestionReadAsAdminMutationResult = Apollo.MutationResult<ToggleQuestionReadAsAdminMutation>;
export type ToggleQuestionReadAsAdminMutationOptions = Apollo.BaseMutationOptions<ToggleQuestionReadAsAdminMutation, ToggleQuestionReadAsAdminMutationVariables>;
export const GetThemesDocument = gql`
    query GetThemes {
  getThemes {
    ...ThemeField
  }
}
    ${ThemeFieldFragmentDoc}`;

/**
 * __useGetThemesQuery__
 *
 * To run a query within a React component, call `useGetThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetThemesQuery(baseOptions?: Apollo.QueryHookOptions<GetThemesQuery, GetThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, options);
      }
export function useGetThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThemesQuery, GetThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, options);
        }
export type GetThemesQueryHookResult = ReturnType<typeof useGetThemesQuery>;
export type GetThemesLazyQueryHookResult = ReturnType<typeof useGetThemesLazyQuery>;
export type GetThemesQueryResult = Apollo.QueryResult<GetThemesQuery, GetThemesQueryVariables>;
export const UpdateThemeAsAdminDocument = gql`
    mutation UpdateThemeAsAdmin($themeInput: ThemeInput!) {
  updateThemeAsAdmin(themeInput: $themeInput) {
    ...ThemeField
  }
}
    ${ThemeFieldFragmentDoc}`;
export type UpdateThemeAsAdminMutationFn = Apollo.MutationFunction<UpdateThemeAsAdminMutation, UpdateThemeAsAdminMutationVariables>;

/**
 * __useUpdateThemeAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateThemeAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThemeAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThemeAsAdminMutation, { data, loading, error }] = useUpdateThemeAsAdminMutation({
 *   variables: {
 *      themeInput: // value for 'themeInput'
 *   },
 * });
 */
export function useUpdateThemeAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateThemeAsAdminMutation, UpdateThemeAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateThemeAsAdminMutation, UpdateThemeAsAdminMutationVariables>(UpdateThemeAsAdminDocument, options);
      }
export type UpdateThemeAsAdminMutationHookResult = ReturnType<typeof useUpdateThemeAsAdminMutation>;
export type UpdateThemeAsAdminMutationResult = Apollo.MutationResult<UpdateThemeAsAdminMutation>;
export type UpdateThemeAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateThemeAsAdminMutation, UpdateThemeAsAdminMutationVariables>;
export const GetMyUserProfileDocument = gql`
    query GetMyUserProfile {
  getMyUserProfile {
    ...UserProfileField
  }
}
    ${UserProfileFieldFragmentDoc}`;

/**
 * __useGetMyUserProfileQuery__
 *
 * To run a query within a React component, call `useGetMyUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetMyUserProfileQuery, GetMyUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyUserProfileQuery, GetMyUserProfileQueryVariables>(GetMyUserProfileDocument, options);
      }
export function useGetMyUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserProfileQuery, GetMyUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyUserProfileQuery, GetMyUserProfileQueryVariables>(GetMyUserProfileDocument, options);
        }
export type GetMyUserProfileQueryHookResult = ReturnType<typeof useGetMyUserProfileQuery>;
export type GetMyUserProfileLazyQueryHookResult = ReturnType<typeof useGetMyUserProfileLazyQuery>;
export type GetMyUserProfileQueryResult = Apollo.QueryResult<GetMyUserProfileQuery, GetMyUserProfileQueryVariables>;
export const GetUserProfileDocument = gql`
    query GetUserProfile($userId: ID!) {
  getUserProfile(userId: $userId) {
    ...UserProfileField
  }
}
    ${UserProfileFieldFragmentDoc}`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const GetAllUsersAsAdminDocument = gql`
    query GetAllUsersAsAdmin($first: Int, $after: String) {
  getAllUsersAsAdmin(first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...UserProfileField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${UserProfileFieldFragmentDoc}`;

/**
 * __useGetAllUsersAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllUsersAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllUsersAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersAsAdminQuery, GetAllUsersAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersAsAdminQuery, GetAllUsersAsAdminQueryVariables>(GetAllUsersAsAdminDocument, options);
      }
export function useGetAllUsersAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersAsAdminQuery, GetAllUsersAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersAsAdminQuery, GetAllUsersAsAdminQueryVariables>(GetAllUsersAsAdminDocument, options);
        }
export type GetAllUsersAsAdminQueryHookResult = ReturnType<typeof useGetAllUsersAsAdminQuery>;
export type GetAllUsersAsAdminLazyQueryHookResult = ReturnType<typeof useGetAllUsersAsAdminLazyQuery>;
export type GetAllUsersAsAdminQueryResult = Apollo.QueryResult<GetAllUsersAsAdminQuery, GetAllUsersAsAdminQueryVariables>;
export const SearchUserDocument = gql`
    query SearchUser($query: String!, $first: Int, $after: String) {
  searchUser(query: $query, first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...UserProfileField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${UserProfileFieldFragmentDoc}`;

/**
 * __useSearchUserQuery__
 *
 * To run a query within a React component, call `useSearchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchUserQuery(baseOptions: Apollo.QueryHookOptions<SearchUserQuery, SearchUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUserQuery, SearchUserQueryVariables>(SearchUserDocument, options);
      }
export function useSearchUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUserQuery, SearchUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUserQuery, SearchUserQueryVariables>(SearchUserDocument, options);
        }
export type SearchUserQueryHookResult = ReturnType<typeof useSearchUserQuery>;
export type SearchUserLazyQueryHookResult = ReturnType<typeof useSearchUserLazyQuery>;
export type SearchUserQueryResult = Apollo.QueryResult<SearchUserQuery, SearchUserQueryVariables>;
export const UpdateUserProfileAsAdminDocument = gql`
    mutation UpdateUserProfileAsAdmin($userId: ID, $userInput: UserInput!) {
  updateUserProfileAsAdmin(userId: $userId, userInput: $userInput) {
    ...UserProfileField
  }
}
    ${UserProfileFieldFragmentDoc}`;
export type UpdateUserProfileAsAdminMutationFn = Apollo.MutationFunction<UpdateUserProfileAsAdminMutation, UpdateUserProfileAsAdminMutationVariables>;

/**
 * __useUpdateUserProfileAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileAsAdminMutation, { data, loading, error }] = useUpdateUserProfileAsAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateUserProfileAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileAsAdminMutation, UpdateUserProfileAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileAsAdminMutation, UpdateUserProfileAsAdminMutationVariables>(UpdateUserProfileAsAdminDocument, options);
      }
export type UpdateUserProfileAsAdminMutationHookResult = ReturnType<typeof useUpdateUserProfileAsAdminMutation>;
export type UpdateUserProfileAsAdminMutationResult = Apollo.MutationResult<UpdateUserProfileAsAdminMutation>;
export type UpdateUserProfileAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileAsAdminMutation, UpdateUserProfileAsAdminMutationVariables>;
export const SetAdminUserAsAdminDocument = gql`
    mutation SetAdminUserAsAdmin($userId: ID!) {
  setAdminUserAsAdmin(userId: $userId)
}
    `;
export type SetAdminUserAsAdminMutationFn = Apollo.MutationFunction<SetAdminUserAsAdminMutation, SetAdminUserAsAdminMutationVariables>;

/**
 * __useSetAdminUserAsAdminMutation__
 *
 * To run a mutation, you first call `useSetAdminUserAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAdminUserAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAdminUserAsAdminMutation, { data, loading, error }] = useSetAdminUserAsAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetAdminUserAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<SetAdminUserAsAdminMutation, SetAdminUserAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAdminUserAsAdminMutation, SetAdminUserAsAdminMutationVariables>(SetAdminUserAsAdminDocument, options);
      }
export type SetAdminUserAsAdminMutationHookResult = ReturnType<typeof useSetAdminUserAsAdminMutation>;
export type SetAdminUserAsAdminMutationResult = Apollo.MutationResult<SetAdminUserAsAdminMutation>;
export type SetAdminUserAsAdminMutationOptions = Apollo.BaseMutationOptions<SetAdminUserAsAdminMutation, SetAdminUserAsAdminMutationVariables>;