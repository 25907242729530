import React from "react";
import OptionInput from "./OptionInput";
import useThemes from "./Themes.hook";
import {
  OptionAddButton,
  OptionAddButtonWrapper,
  OptionCategoryTitle,
  OptionCategoryWrapper,
  OptionsWrapper,
} from "../styles/optionStyles";

function Themes() {
  const {
    models: { additionMode, loading, data },
    operations: { onSubmit, onAddClick, onAdditionCancel },
  } = useThemes();

  if (loading) return <div />;

  return (
    <OptionCategoryWrapper>
      <OptionCategoryTitle>주제</OptionCategoryTitle>
      <OptionsWrapper>
        {data?.map((theme) => (
          <OptionInput
            key={theme?.id}
            showPhraseInput={true}
            value={{
              id: theme?.id,
              name: theme?.name,
              phrase: theme?.phrase,
              iconUrl: theme?.iconUrl,
              order: theme?.order,
            }}
            onSubmit={onSubmit}
          />
        ))}

        {additionMode && (
          <OptionInput
            value={null}
            showPhraseInput={true}
            onSubmit={onSubmit}
            onAdditionCancel={onAdditionCancel}
          />
        )}

        {!additionMode && (
          <OptionAddButtonWrapper>
            <OptionAddButton onClick={onAddClick}>추가</OptionAddButton>
          </OptionAddButtonWrapper>
        )}
      </OptionsWrapper>
    </OptionCategoryWrapper>
  );
}

export default Themes;
