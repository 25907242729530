import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useManagementValue from "./ManagementValue.hook";
import { Management } from "../../../lib/apollo/graphql/generated";
import { Col, Input, Row } from "../../common/styles/commonStyles";

interface ManagementValueProps {
  management: Management;
}

function ManagementValue({ management }: ManagementValueProps) {
  const {
    models: { state, updateLoading },
    operations: { onInputChange, onSubmit },
  } = useManagementValue(management);

  return (
    <>
      <ButtonWrapper>
        <div>LANGUAGE: {management?.languageCode}</div>
        <button
          style={{ fontSize: "inherit" }}
          disabled={updateLoading || !(state.homeThemeCount && state.mainTitle)}
          onClick={onSubmit}
        >
          업데이트!
        </button>
      </ButtonWrapper>
      <Wrapper>
        {Object.keys(state).map((key) =>
          key === "mainTitle" ||
          key === "mainDescription" ||
          key === "mainIntroductionTitle" ||
          key === "mainIntroductionSubtitle" ||
          key === "mainIntroduction" ||
          key === "refundPolicy" ? (
            <InputWrapper key={key}>
              <Label>{key}</Label>
              <Textarea
                value={state[key]}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  onInputChange(e, key)
                }
              />
            </InputWrapper>
          ) : (
            <InputWrapper key={key}>
              <Label>{key}</Label>
              <Input
                value={state[key]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onInputChange(e, key)
                }
              />
            </InputWrapper>
          )
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridAutoRows: "auto",
  gap: theme.spacing.middle,
  padding: theme.spacing.middle,
}));

const ButtonWrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  justifyContent: "flex-end",
  margin: theme.spacing.middle,
  ...theme.font.head4,
}));

const InputWrapper = styled(Col)<any>(() => ({
  textAlign: "left",
  width: "100%",
}));

const Label = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head5,
  fontWeight: "bold",
  marginBottom: theme.spacing.small,
}));

const Textarea = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body1,
  resize: "none",
  height: 100,
}));

export default ManagementValue;
