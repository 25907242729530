import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useMainContainer from "./MainContainer.hook";
import MainCard from "../components/MainCard";

function MainContainer() {
  const {
    models: { loading, data },
  } = useMainContainer();

  if (loading) return <div />;

  return (
    <Wrapper>
      <MainCard title="이메일 구독자 수" value={data!} />
    </Wrapper>
  );
}

export const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
  display: "grid",
  gridTemplateColumns: "repeat(4,1fr)",
  gap: theme.spacing.small,
  gridAutoRows: "auto",
}));

export default MainContainer;
