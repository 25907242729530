import { useGetAllUsersAsAdminQuery } from "../../../../lib/apollo/graphql/generated";

function useUserListContainer() {
  const { loading, data } = useGetAllUsersAsAdminQuery({
    variables: {
      first: 20,
    },
  });

  return {
    models: {
      loading,
      data: data?.getAllUsersAsAdmin?.edges,
    },
  };
}

export default useUserListContainer;
