import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useAuthContainer from "./AuthContainer.hook";
import logo from "../../../assets/icons/logo_green.svg";

function AuthContainer() {
  const {
    models: { state },
    operations: { onChange, onSubmit },
  } = useAuthContainer();

  const { email, password } = state;

  return (
    <Wrapper>
      {/* TODO: CHANGE  */}
      <Title>Footsketch Admin</Title>
      <Body>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <AuthInputWrapper>
          <AuthInput
            placeholder="Email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, "email")
            }
          />
          <AuthInput
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, "password")
            }
          />
          <Button onClick={onSubmit}>Sign In</Button>
        </AuthInputWrapper>
      </Body>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  height: "100vh",
  position: "relative",
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head5,
  color: theme.color.black,
  marginBottom: theme.spacing.middle,
}));

const Body = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "60%",
  border: `1px solid ${theme.color.black7}`,
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "center",
  padding: `${theme.spacing.regular}px 0px`,
  backgroundColor: "rgba(0,0,0,0.1)",
}));

const LogoWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  padding: `0px ${theme.spacing.regular}px`,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

const Logo = styled.img.attrs(() => ({
  src: logo,
}))<any>(() => ({
  width: "100%",
}));

const AuthInputWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: `0px ${theme.spacing.regular}px`,
  gap: theme.spacing.regular,
  borderLeft: `1px solid ${theme.color.black7}`,
}));

const AuthInput = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  borderBottom: `1px solid ${theme.color.black6}`,
}));

const Button = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
  ...theme.font.body2,
  color: theme.color.white,
  backgroundColor: theme.color.black1,
}));

export default AuthContainer;
