import styled, { ThemedComponentProps } from "styled-components";

export const Row = styled.div<any>(() => ({
  display: "flex",
  alignItems: "center",
}));

export const Col = styled.div<any>(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  borderBottom: `1px solid ${theme.color.black7}`,
  flex: 1,
  ...theme.font.body1,
}));

export const Table = styled.table<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: "100%",
  borderSpacing: 0,
}));

export const Head = styled.thead<any>(() => ({
  padding: 0,
}));

export const Th = styled.th<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black6}`,
  backgroundColor: theme.color.black8,
  padding: theme.spacing.small,
  textAlign: "left",
}));

export const TBody = styled.tbody<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: "100%",
  overflowY: "auto",
  ...theme.font.body2,
}));

export const Td = styled.td<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.small,
  border: `1px solid ${theme.color.black6}`,
}));

export const Tabs = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  gap: theme.spacing.small,
  marginBottom: theme.spacing.default,
}));

interface TabProps extends ThemedComponentProps {
  active?: boolean;
}

export const Tab = styled.button<any>(({ theme, active }: TabProps) => ({
  padding: theme.spacing.small,
  backgroundColor: active ? theme.color.black : theme.color.black3,
  color: theme.color.white,
  ...theme.font.body3,

  ":hover": {
    backgroundColor: theme.color.black5,
  },
}));
