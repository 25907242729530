import React, { useEffect, useState } from "react";
import {
  GetCreateMoimFaqsAsAdminDocument,
  useUpdateCreateMoimFaqAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

const initialState = {
  id: "",
  question: "",
  answer: "",
  active: true,
  languageCode: "",
};

function useCreateMoimFaqCard(faq: any) {
  const [state, setState] = useState(initialState);

  const [updateCreateMoimFaqAsAdmin] = useUpdateCreateMoimFaqAsAdminMutation({
    onCompleted: () => {
      if (!faq?.id) {
        setState(initialState);
      }
    },
    refetchQueries: !faq?.id
      ? () => [{ query: GetCreateMoimFaqsAsAdminDocument }]
      : undefined,
  });

  useEffect(() => {
    if (faq) {
      setState({
        id: faq?.id,
        question: faq?.question,
        answer: faq?.answer,
        active: faq?.active || true,
        languageCode: faq?.languageCode,
      });
    }
  }, [faq]);

  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    if (key !== "active") {
      setState((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      const { checked } = (e as React.ChangeEvent<HTMLInputElement>).target;

      setState((prev) => ({
        ...prev,
        [key]: checked,
      }));
    }
  }

  function onSubmit() {
    console.log(state);

    updateCreateMoimFaqAsAdmin({
      variables: {
        createMoimFaqInput: {
          id: faq?.id || null,
          question: state?.question,
          answer: state?.answer,
          active: state?.active,
          languageCode: state?.languageCode,
        },
      },
    });
  }

  console.log(state);

  return {
    models: {
      state,
    },
    operations: {
      onInputChange,
      onSubmit,
    },
  };
}

export default useCreateMoimFaqCard;
