import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useManagementContainer from "./ManagementContainer.hook";
import Loading from "../../common/components/Loading";
import { Col, Input, Row } from "../../common/styles/commonStyles";
import ManagementValue from "../components/ManagementValue";

function ManagementContainer() {
  const {
    models: { data, loading, updateLoading, addingLanguageCode },
    operations: { onAddingLanguageCodeChange, onAddClick },
  } = useManagementContainer();

  if (loading) return <Loading />;

  return (
    <Wrapper>
      <ButtonWrapper>
        <Input
          value={addingLanguageCode}
          placeholder="추가할 language code"
          onChange={onAddingLanguageCodeChange}
        />
        <button
          style={{ fontSize: "inherit" }}
          disabled={loading || updateLoading}
          onClick={onAddClick}
        >
          추가하기!
        </button>
      </ButtonWrapper>
      {data?.map((management) => (
        <ManagementValue key={management?.id} management={management!} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const ButtonWrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  gap: theme.spacing.small,
  ...theme.font.body3,
  maxWidth: "50%",
}));

export default ManagementContainer;
