import React from "react";
import styled, { ThemedComponentProps } from "styled-components";

interface MainCardProps {
  title: string;
  value: string | number;
}

function MainCard({ title, value }: MainCardProps) {
  return (
    <Wrapper>
      <div>
        <strong>{title}</strong>
      </div>
      <Value>{value}</Value>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.default}px`,
  ...theme.font.body3,
  border: `1px solid ${theme.color.black4}`,
}));

const Value = styled.div<any>(() => ({
  width: "100%",
  textAlign: "right",
}));

export default MainCard;
