import React, { useState } from "react";
import { firebaseSignIn } from "../../../lib/firebase";

function useAuthContainer() {
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  function onChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  async function onSubmit() {
    const { email, password } = state;

    try {
      await firebaseSignIn(email, password);
    } catch (err) {
      alert(err);
    }
  }

  return {
    models: {
      state,
    },
    operations: {
      onChange,
      onSubmit,
    },
  };
}

export default useAuthContainer;
