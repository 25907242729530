import { useGetEmailListsAsAdminQuery } from "../../../lib/apollo/graphql/generated";
import { copyText } from "../../common/utils/copyText";

function useEmailListContainer() {
  const { loading, data } = useGetEmailListsAsAdminQuery({
    variables: {
      first: 20,
    },
  });

  function onCopyAllClick() {
    if (
      data?.getEmailListsAsAdmin?.edges &&
      data?.getEmailListsAsAdmin?.edges?.length > 0
    ) {
      let copyingEmails = "";

      data?.getEmailListsAsAdmin?.edges?.forEach((emailList) => {
        copyingEmails += emailList?.email + ", ";
      });

      copyText(copyingEmails, "모든 이메일 복사 완료!");
    }
  }

  function onEmailClick(email: string) {
    copyText(email, email + " 복사 완료!");
  }

  return {
    models: {
      loading,
      data: data?.getEmailListsAsAdmin?.edges,
    },
    operations: {
      onCopyAllClick,
      onEmailClick,
    },
  };
}

export default useEmailListContainer;
