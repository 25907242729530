import { useState } from "react";
import { useToggleHostVerifiedAsAdminMutation } from "../../../../lib/apollo/graphql/generated";

function useHostListRow() {
  const [viewMore, setViewMore] = useState(false);

  const [toggleHostVerified, { loading }] =
    useToggleHostVerifiedAsAdminMutation();

  function toggleViewMore() {
    setViewMore(!viewMore);
  }

  function onVerifyClick(hostId: string, verified: boolean) {
    toggleHostVerified({
      variables: {
        hostId,
        verified: !verified,
      },
    });
  }

  return {
    models: {
      viewMore,
      loading,
    },
    operations: {
      toggleViewMore,
      onVerifyClick,
    },
  };
}

export default useHostListRow;
