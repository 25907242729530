import { initializeApp } from "firebase/app";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);

export async function firebaseSignUp(email: string, password: string) {
  await createUserWithEmailAndPassword(auth, email, password);
}

export async function firebaseSignIn(email: string, password: string) {
  await signInWithEmailAndPassword(auth, email, password);
}

export async function firebaseSignOut() {
  await signOut(auth);
}

export async function getFirebaseIdToken() {
  return await auth.currentUser?.getIdToken();
}

export async function firebaseSendPasswordResetEmail(email: string) {
  await sendPasswordResetEmail(auth, email);
}

// 유저인증
export async function firebaseReAuthenticate(currentPassword: string) {
  const { currentUser } = auth;
  const credential = EmailAuthProvider.credential(
    currentUser!.email!,
    currentPassword
  );

  await reauthenticateWithCredential(currentUser!, credential);
}

export async function firebaseUpdatePassword(newPassword: string) {
  const user = auth.currentUser;
  await updatePassword(user!, newPassword);
}

export async function uploadToFirebaseStorage(
  file: any,
  metadata: any,
  category: string
): Promise<string> {
  const storageRef = ref(storage, category + "/" + new Date() + file.name);
  const uploadTask = uploadBytesResumable(storageRef, file, metadata);

  return new Promise((resolve) => {
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        console.log(error);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}
