import { ModalProps } from "global";
import moment from "moment";
import React from "react";
import ReactModal from "react-modal";
import styled, { ThemedComponentProps } from "styled-components";
import useMoimDetailModal from "./MoimDetailModal.hook";
import MoimDetailModalContent from "./MoimDetailModalContent";
import { Gender, MoimStatus } from "../../../../lib/apollo/graphql/generated";
import { MOIM_STATUS } from "../../../../utilites/constants";
import Avatar from "../../../common/components/Avatar";
import Loading from "../../../common/components/Loading";
import { Row } from "../../../common/styles/commonStyles";

interface MoimDetailModalProps extends ModalProps {
  moimId: string;
}

function MoimDetailModal({ moimId, ...rest }: MoimDetailModalProps) {
  const {
    models: { loading, data, message, status },
    operations: {
      onAddContentClick,
      onMessageChange,
      onStatusChange,
      onSubmit,
    },
  } = useMoimDetailModal(moimId);

  if (loading || !data) return <Loading />;

  const {
    id,
    coverPhoto,
    moimContents,
    status: _status,
    host,
    place,
    moimStartAt,
    moimEndAt,
    maxPersons,
    minPersons,
    minAge,
    maxAge,
    genderRestriction,
    viewCount,
  } = data;

  return (
    <ReactModal {...rest}>
      <div>
        <Label>MOIM ID</Label>
        <Value>{id}</Value>
      </div>
      <SectionTitle>관리자 수정사항</SectionTitle>
      <AdminRow>
        <div style={{ flex: 1 }}>
          <Label>메세지</Label>
          <Textarea
            value={message}
            placeholder="상태 변경 메세지"
            onChange={onMessageChange}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Label>변경할 상태</Label>
          <select value={status} onChange={onStatusChange}>
            {Object.values(MOIM_STATUS).map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
        <div style={{ flex: 0.3 }}>
          <button onClick={onSubmit}>변경사항 제출하기</button>
        </div>
      </AdminRow>

      <SectionTitle>호스트 정보</SectionTitle>
      <Grid>
        <div>
          <Label>호스트 ID</Label>
          <Value>{host?.id}</Value>
        </div>
        <div>
          <Label>호스트 프사</Label>
          <Value>
            <Avatar uri={host?.user?.avatar?.uri} diameter={40} />
          </Value>
        </div>
        <div>
          <Label>호스트 이름</Label>
          <Value>{host?.user?.name}</Value>
        </div>
        <div>
          <Label>호스트 유저네임</Label>
          <Value>{host?.user?.username}</Value>
        </div>
        <div>
          <Label>호스트 이메일</Label>
          <Value>{host?.user?.email}</Value>
        </div>
        <div>
          <Label>호스트 전화번호</Label>
          <Value>{host?.user?.phone}</Value>
        </div>
        <div>
          <Label>호스트 성별</Label>
          <Value>{host?.user?.gender}</Value>
        </div>
        <div>
          <Label>호스트 생년월일</Label>
          <Value>
            {host?.user?.dateOfBirth},{" "}
            {!!host?.user?.dateOfBirth &&
              moment().diff(host?.user?.dateOfBirth, "years") + "세"}
          </Value>
        </div>
        <div>
          <Label>호스트 국적</Label>
          <Value>{host?.user?.nationality?.name}</Value>
        </div>
      </Grid>

      <SectionTitle>장소 정보</SectionTitle>
      <Grid>
        <div>
          <Label>장소 이름</Label>
          <Value>{place?.name}</Value>
        </div>
        <div>
          <Label>장소 주소</Label>
          <Value>
            {place?.address}, {place?.addressDetail}
          </Value>
        </div>
        <div>
          <Label>프라이빗 여부</Label>
          <Value>{place?.isPrivate ? "O" : "X"}</Value>
        </div>
      </Grid>

      <SectionTitle>정산 정보</SectionTitle>
      <Grid>
        <div>
          <Label>은행명</Label>
          <Value>{host?.bankName}</Value>
        </div>
        <div>
          <Label>계좌번호</Label>
          <Value>{host?.bankAccount}</Value>
        </div>
        <div>
          <Label>주민번호(사업자등록번호)</Label>
          <Value>
            {host?.business
              ? host?.business?.registrationNumber
              : host?.idNumber}
          </Value>
        </div>
        {host?.business && (
          <div>
            <Label>세금 이메일(사업자만)</Label>
            <Value>{host?.business?.taxEmail}</Value>
          </div>
        )}
      </Grid>

      <SectionTitle>모임 정보</SectionTitle>
      <Grid>
        <div>
          <Label>모임 상태</Label>
          <Value>{_status}</Value>
        </div>
        <div>
          <Label>모임 시작시각</Label>
          <Value>
            {moimStartAt ? moment(moimStartAt).format("YY-MM-DD HH:mm") : "-"}
          </Value>
        </div>
        <div>
          <Label>모임 종료시각</Label>
          <Value>
            {moimEndAt ? moment(moimEndAt).format("YY-MM-DD HH:mm") : "-"}
          </Value>
        </div>
        <div>
          <Label>모임 최소인원</Label>
          <Value>{minPersons || "-"}</Value>
        </div>
        <div>
          <Label>모임 최대인원</Label>
          <Value>{maxPersons || "-"}</Value>
        </div>
        <div>
          <Label>모임 최소나이</Label>
          <Value>{minAge || "-"}</Value>
        </div>
        <div>
          <Label>모임 최대나이</Label>
          <Value>{maxAge || "-"}</Value>
        </div>
        <div>
          <Label>성별제한</Label>
          <Value>{genderRestriction}</Value>
        </div>
      </Grid>

      <SectionTitle>모임 상세정보</SectionTitle>
      {moimContents?.map((content) => {
        return (
          <MoimDetailModalContent
            key={content?.id}
            moimId={id}
            content={content!}
          />
        );
      })}
      {moimContents?.length === 1 && (
        <button
          onClick={() =>
            onAddContentClick(
              moimContents?.[0]?.languageCode === "ko" ? "en" : "ko"
            )
          }
        >
          컨텐츠 언어 추가
        </button>
      )}
    </ReactModal>
  );
}

const AdminRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: theme.spacing.normal,
}));

const Textarea = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  border: `1px solid ${theme.color.black6}`,
  padding: theme.spacing.small,
  height: 100,
  resize: "none",
}));

const Label = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body2,
  fontWeight: "bold",
}));

const Value = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body2,
}));

const SectionTitle = styled(Label)<any>(({ theme }: ThemedComponentProps) => ({
  marginTop: theme.spacing.default,
  marginBottom: theme.spacing.small,
  ...theme.font.body1,
}));

const Grid = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridAutoRows: "auto",
  gap: theme.spacing.small,
  margin: theme.spacing.small,
}));

export default MoimDetailModal;
