import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useHostListContainer from "./HostListContainer.hook";
import { HOST_LIST_TAB } from "../../../../utilites/constants";
import Loading from "../../../common/components/Loading";
import HostListRow from "../components/HostListRow";
import {
  Head,
  Row,
  Tab,
  Table,
  Tabs,
  TBody,
  Th,
} from "../../../common/styles/commonStyles";

function HostListContainer() {
  const {
    models: { loading, data, tab },
    operations: { toggleTab },
  } = useHostListContainer();

  if (loading) return <Loading />;

  return (
    <Wrapper>
      <Tabs>
        {Object.values(HOST_LIST_TAB).map((value) => (
          <Tab
            key={value}
            active={tab === value}
            onClick={() => toggleTab(value)}
          >
            {value}
          </Tab>
        ))}
      </Tabs>

      <Table>
        <Head>
          <tr>
            <Th>ID</Th>
            <Th>프사</Th>
            <Th>이메일</Th>
            <Th>유저네임</Th>
            <Th>이름</Th>
            <Th>생년월일</Th>
            <Th>성별</Th>
            <Th>국적</Th>
            <Th>사업자여부</Th>
            <Th>호스트신청일</Th>
            <Th>자세히보기</Th>
          </tr>
        </Head>
        <TBody>
          {data?.map((host) => (
            <HostListRow key={host?.id} host={host!} />
          ))}
        </TBody>
      </Table>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.default,
  position: "relative",
}));

export default HostListContainer;
