import { useState } from "react";

import {
  GetJobsDocument,
  JobInput,
  useGetJobsQuery,
  useUpdateJobAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useJobs() {
  const [additionMode, setAdditionMode] = useState(false);
  const { loading, data, refetch } = useGetJobsQuery();

  const [updateJobAsAdmin] = useUpdateJobAsAdminMutation();

  function onAddClick() {
    setAdditionMode(true);
  }

  function onAdditionCancel() {
    setAdditionMode(false);
  }

  function onSubmit({
    id,
    name,
    iconUrl,
    order,
  }: {
    id: string | null;
    name: string;
    iconUrl: string;
    order: number;
  }) {
    const jobInput: JobInput = {
      id: id || null,
      name,
      iconUrl: iconUrl || null,
      order,
    };

    updateJobAsAdmin({
      variables: {
        jobInput,
      },
      refetchQueries: !id
        ? () => [
            {
              query: GetJobsDocument,
            },
          ]
        : undefined,
    });
  }

  return {
    models: {
      additionMode,
      loading,
      data: data?.getJobs,
    },

    operations: {
      onSubmit,
      onAddClick,
      onAdditionCancel,
    },
  };
}

export default useJobs;
