import { ReactElement } from "react";
import EmailListContainer from "../../../modules/emailList/container/EmailListContainer";
import HostListContainer from "../../../modules/host/hostList/container/HostListContainer";
import MainContainer from "../../../modules/main/container/MainContainer";
import ManageCreateMoimFaqContainer from "../../../modules/manageCreateMoimFaq/container/ManageCreateMoimFaqContainer";
import ManagementContainer from "../../../modules/management/container/ManagementContainer";
import MoimListContainer from "../../../modules/moim/moimList/container/MoimListContainer";
import OptionContainer from "../../../modules/option/container/OptionContainer";
import UpdateUserContainer from "../../../modules/user/updateUser/container/UpdateUserContainer";
import UserListContainer from "../../../modules/user/userList/container/UserListContainer";

export type CustomRoute = {
  path: string;
  element: ReactElement;
  show: boolean;
  name?: string;
  children?: CustomRoute[];
};

export const PATH_NAMES: { [key: string]: CustomRoute } = {
  HOME: {
    path: "/",
    name: "홈",
    show: true,
    element: <MainContainer />,
  },
  USER: {
    path: "/users",
    name: "유저목록",
    show: true,
    element: <UserListContainer />,
  },
  UPDATE_USER: {
    path: "/user/:userId",
    name: "유저관리",
    show: false,
    element: <UpdateUserContainer />,
  },
  HOST_LIST: {
    path: "/hosts",
    name: "호스트 목록",
    show: true,
    element: <HostListContainer />,
  },
  MOIM_LIST: {
    path: "/moims",
    name: "모임 목록",
    show: true,
    element: <MoimListContainer />,
  },
  EMAIL_LIST: {
    path: "/email_list",
    name: "이메일구독",
    show: true,
    element: <EmailListContainer />,
  },
  OPTION: {
    path: "/option",
    name: "업종,직무,직업,주제",
    show: true,
    element: <OptionContainer />,
  },

  MANAGEMENT: {
    path: "/management",
    name: "관리",
    show: true,
    element: <ManagementContainer />,
  },

  CREATE_MOIM_FAQ: {
    path: "/create_moim_faq",
    name: "모임 FAQ 관리",
    show: true,
    element: <ManageCreateMoimFaqContainer />,
  },
};
