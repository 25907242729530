import React, { useEffect, useState } from "react";
import { useUpdateMoimContentAsAdminMutation } from "../../../../lib/apollo/graphql/generated";

interface useMoimDetailModalContentProps {
  moimId: string;
  content: {
    __typename?: "MoimContent";
    id: string;
    languageCode: string;
    title?: string | null;
    introduction?: string | null;
    detail?: string | null;
    price?: number | null;
    createdAt: any;
    updatedAt: any;
  };
}

function useMoimDetailModalContent({
  moimId,
  content,
}: useMoimDetailModalContentProps) {
  const [state, setState] = useState({
    title: content?.title || "",
    price: content?.price?.toString() || "",
    introduction: content?.introduction || "",
    detail: content?.detail || "",
  });

  const [updateMoimContentAsAdmin] = useUpdateMoimContentAsAdminMutation();

  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSubmit() {
    const { title, detail, introduction, price } = state;

    updateMoimContentAsAdmin({
      variables: {
        adminMoimContentInput: {
          id: content?.id,
          moimId: moimId,
          languageCode: content?.languageCode,
          title,
          detail,
          introduction,
          price: Number(price) || null,
        },
      },
    });
  }

  return {
    models: {
      state,
    },
    operations: {
      onInputChange,
      onSubmit,
    },
  };
}

export default useMoimDetailModalContent;
