import React from "react";
import styled from "styled-components";
import { ThemedComponentProps } from "styled-components";
import useMoimListContainer from "./MoimListContainer.hook";
import { MoimStatus } from "../../../../lib/apollo/graphql/generated";
import { MOIM_STATUS } from "../../../../utilites/constants";
import Loading from "../../../common/components/Loading";
import { Tab, Tabs } from "../../../common/styles/commonStyles";
import MoimListCard from "../components/MoimListCard";

function MoimListContainer() {
  const {
    models: { loading, data, tab },
    operations: { toggleTab },
  } = useMoimListContainer();

  if (loading) return <Loading />;

  return (
    <Wrapper>
      <Tabs>
        {Object.values(MOIM_STATUS).map((value) => (
          <Tab
            key={value}
            active={tab === value}
            onClick={() => toggleTab(value)}
          >
            {value}
          </Tab>
        ))}
      </Tabs>
      <CardsWrapper>
        {data?.map((moim) => (
          <MoimListCard key={moim?.id} moim={moim!} />
        ))}
      </CardsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.default,
  position: "relative",
}));

const CardsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridAutoRows: "auto",
  gap: theme.spacing.normal,
}));

export default MoimListContainer;
