import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useCreateMoimFaqCard from "./CreateMoimFaqCard.hook";

interface CreateMoimFaqCardProps {
  faq: any;
}

function CreateMoimFaqCard({ faq }: CreateMoimFaqCardProps) {
  const {
    models: { state },
    operations: { onInputChange, onSubmit },
  } = useCreateMoimFaqCard(faq);
  return (
    <Wrapper>
      <Row>
        <div>ID</div>
        <Input disabled defaultValue={state?.id} />
      </Row>
      <Row>
        <div>질문</div>
        <Input
          value={state?.question}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "question")
          }
        />
      </Row>
      <Row>
        <div>답변</div>
        <Textarea
          value={state?.answer}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onInputChange(e, "answer")
          }
        />
      </Row>
      <Row>
        <div>활성화</div>
        <Input
          type="checkbox"
          checked={state?.active}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "active")
          }
        />
      </Row>
      <Row>
        <div>언어</div>
        <Input disabled defaultValue={state?.languageCode} />
      </Row>
      <ButtonsWrapper>
        <Button onClick={onSubmit}>제출</Button>
      </ButtonsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing.xxSmall2,
  padding: `${theme.spacing.xxSmall2}px 0px`,
  borderBottom: `1px solid ${theme.color.black6}`,
  ...theme.font.body2,
  width: "100%",
}));

const Row = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.small,
  padding: `0px ${theme.spacing.xxSmall2}px`,
  width: "100%",
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  borderBottom: `1px solid ${theme.color.black7}`,
  flex: 1,
}));

const Textarea = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  borderBottom: `1px solid ${theme.color.black7}`,
  flex: 1,
  height: 150,
  resize: "none",
}));

const ButtonsWrapper = styled(Row)<any>(() => ({
  justifyContent: "flex-end",
}));

const Button = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  backgroundColor: theme.color.black2,
  ...theme.font.body3,
  color: theme.color.white,
}));

export default CreateMoimFaqCard;
