import { useState } from "react";
import { MOIM_STATUS } from "../../../../utilites/constants";
import {
  GetMoimDocument,
  MoimStatus,
  useGetMoimQuery,
  useSetMoimStatusAsAdminMutation,
  useUpdateMoimContentAsAdminMutation,
} from "../../../../lib/apollo/graphql/generated";

function useMoimDetailModal(moimId: string) {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(MOIM_STATUS.EDITING);

  const { loading, data } = useGetMoimQuery({
    variables: {
      moimId,
    },
    onCompleted: (data) => {
      if (data?.getMoim?.message) {
        setMessage(data?.getMoim?.message);
      }
      setStatus(data?.getMoim?.status);
    },
  });

  const [updateMoimContentAsAdmin] = useUpdateMoimContentAsAdminMutation({
    refetchQueries: () => [
      {
        query: GetMoimDocument,
        variables: {
          moimId,
        },
      },
    ],
  });

  const [setMoimStatusAsAdmin] = useSetMoimStatusAsAdminMutation();

  function onMessageChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target;
    setMessage(value);
  }

  function onStatusChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = e.target;

    setStatus(value);
  }

  function onAddContentClick(languageCode: string) {
    updateMoimContentAsAdmin({
      variables: {
        adminMoimContentInput: {
          moimId,
          languageCode,
        },
      },
    });
  }

  function onSubmit() {
    setMoimStatusAsAdmin({
      variables: {
        moimId: moimId,
        message,
        status: status as MoimStatus,
      },
    });
  }

  return {
    models: {
      loading,
      data: data?.getMoim,
      message,
      status,
    },
    operations: {
      onAddContentClick,
      onMessageChange,
      onStatusChange,
      onSubmit,
    },
  };
}

export default useMoimDetailModal;
