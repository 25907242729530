import React, { useState } from "react";
import {
  GetManagementsAsAdminDocument,
  useGetManagementsAsAdminQuery,
  useUpdateManagementAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useManagementContainer() {
  const [addingLanguageCode, setAddingLanguageCode] = useState("");

  const { data, loading } = useGetManagementsAsAdminQuery();

  const [updateManagementAsAdmin, { loading: updateLoading }] =
    useUpdateManagementAsAdminMutation({
      onCompleted: () => {
        alert("성공!");
      },
      refetchQueries: () => [
        {
          query: GetManagementsAsAdminDocument,
        },
      ],
    });

  function onAddingLanguageCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setAddingLanguageCode(value);
  }

  function onAddClick() {
    updateManagementAsAdmin({
      variables: {
        managementInput: {
          languageCode: addingLanguageCode,
        },
      },
    });
  }

  return {
    models: {
      data: data?.getManagementsAsAdmin,
      loading,
      updateLoading,
      addingLanguageCode,
    },
    operations: {
      onAddingLanguageCodeChange,
      onAddClick,
    },
  };
}

export default useManagementContainer;
