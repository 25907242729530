import { Route, RouteObject } from "react-router-dom";
import { PATH_NAMES } from "../utils/router";

function useRouterContainer() {
  /**
   * Get all the routes from router.tsx and make it as Route components
   * @param routes route object
   */
  function getRoutes() {
    return Object.keys(PATH_NAMES).map((key) => {
      const route = PATH_NAMES[key];

      if (route?.children) {
        return (
          <Route key={route.path} path={route.path} element={route.element}>
            {route?.children?.map((child) => (
              <Route
                key={child.path}
                path={child.path}
                element={child.element}
              />
            ))}
          </Route>
        );
      }

      return (
        <Route key={route.path} path={route.path} element={route.element} />
      );
    });
  }

  return {
    operations: {
      getRoutes,
    },
  };
}

export default useRouterContainer;
