import { useGetEmailListsCountAsAdminQuery } from "../../../lib/apollo/graphql/generated";

function useMainContainer() {
  const { data, loading } = useGetEmailListsCountAsAdminQuery();

  return {
    models: {
      loading,
      data: data?.getEmailListsCountAsAdmin,
    },
  };
}

export default useMainContainer;
