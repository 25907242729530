import React from "react";
import useOccupations from "./Occupation.hook";
import OptionInput from "./OptionInput";
import {
  OptionAddButton,
  OptionAddButtonWrapper,
  OptionCategoryTitle,
  OptionCategoryWrapper,
  OptionsWrapper,
} from "../styles/optionStyles";

function Occupations() {
  const {
    models: { additionMode, loading, data },
    operations: { onSubmit, onAddClick, onAdditionCancel },
  } = useOccupations();

  if (loading) return <div />;

  return (
    <OptionCategoryWrapper>
      <OptionCategoryTitle>직업</OptionCategoryTitle>
      <OptionsWrapper>
        {data?.map((occupation) => (
          <OptionInput
            key={occupation?.id}
            value={{
              id: occupation?.id,
              name: occupation?.name,
              iconUrl: occupation?.iconUrl,
              order: occupation?.order,
            }}
            onSubmit={onSubmit}
          />
        ))}

        {additionMode && (
          <OptionInput
            value={null}
            onSubmit={onSubmit}
            onAdditionCancel={onAdditionCancel}
          />
        )}

        {!additionMode && (
          <OptionAddButtonWrapper>
            <OptionAddButton onClick={onAddClick}>추가</OptionAddButton>
          </OptionAddButtonWrapper>
        )}
      </OptionsWrapper>
    </OptionCategoryWrapper>
  );
}

export default Occupations;
