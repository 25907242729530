import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useMoimDetailModalContent from "./MoimDetailModalContent.hook";

interface MoimDetailModalContentProps {
  moimId: string;
  content: {
    __typename?: "MoimContent";
    id: string;
    languageCode: string;
    title?: string | null;
    introduction?: string | null;
    detail?: string | null;
    price?: number | null;
    createdAt: any;
    updatedAt: any;
  };
}

function MoimDetailModalContent({
  moimId,
  content,
}: MoimDetailModalContentProps) {
  const {
    models: { state },
    operations: { onInputChange, onSubmit },
  } = useMoimDetailModalContent({ moimId, content });

  return (
    <Wrapper>
      <div>
        <span>Language: {content?.languageCode}</span>
        <span>
          <button onClick={onSubmit}>제출하기</button>
        </span>
      </div>
      <Grid>
        {Object.keys(state).map((key: string) => (
          <div key={key}>
            <div>{key}</div>
            {key === "introduction" || key === "detail" ? (
              <Textarea
                value={state[key]}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  onInputChange(e, key)
                }
              />
            ) : key === "title" || key === "price" ? (
              <Input
                value={state[key]}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  onInputChange(e, key)
                }
              />
            ) : null}
          </div>
        ))}
      </Grid>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  margin: theme.spacing.small,
}));

const Grid = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridAutoRows: "auto",
  gap: theme.spacing.small,
  margin: theme.spacing.small,
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  border: `1px solid ${theme.color.black6}`,
  padding: theme.spacing.small,
}));

const Textarea = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  border: `1px solid ${theme.color.black6}`,
  padding: theme.spacing.small,
  height: 300,
  resize: "none",
}));

export default MoimDetailModalContent;
