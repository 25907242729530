import moment from "moment";
import React, { useState } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useHostListRow from "./HostListRow.hook";
import { Gender, Host } from "../../../../lib/apollo/graphql/generated";
import Avatar from "../../../common/components/Avatar";
import { Td } from "../../../common/styles/commonStyles";

interface HostListRowProps {
  host: {
    __typename?: "Host";
    id: string;
    bankName?: string | null;
    bankAccount?: string | null;
    introduction?: string | null;
    verified: boolean;
    idNumber?: string | null;
    createdAt: any;
    updatedAt: any;
    bankAccountMedia?: {
      __typename?: "Media";
      id: string;
      uri: string;
    } | null;
    business?: {
      __typename?: "Business";
      id: string;
      name: string;
      ownerName: string;
      registrationNumber: string;
      taxEmail: string;
      createdAt: any;
      updatedAt: any;
      registrationMedia: { __typename?: "Media"; id: string; uri: string };
    } | null;
    user: {
      __typename?: "UserProfile";
      id: string;
      username: string;
      name?: string | null;
      email?: string | null;
      phone?: string | null;
      dateOfBirth?: any | null;
      gender?: Gender | null;
      createdAt: any;
      updatedAt: any;
      nationality?: {
        __typename?: "Nationality";
        id: string;
        name: string;
      } | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    };
  };
}

function HostListRow({ host }: HostListRowProps) {
  const {
    models: { viewMore, loading },
    operations: { toggleViewMore, onVerifyClick },
  } = useHostListRow();

  const {
    id,
    bankName,
    bankAccount,
    bankAccountMedia,
    introduction,
    idNumber,
    business,
    user,
    verified,
    createdAt,
    updatedAt,
  } = host;

  return (
    <>
      <tr>
        <Td>{id}</Td>
        <Td>
          <Avatar uri={user?.avatar?.uri} diameter={50} />
        </Td>
        <Td>{user?.email}</Td>
        <Td>{user?.username || ""}</Td>
        <Td>{user?.name || ""}</Td>
        <Td>{user?.dateOfBirth || ""}</Td>
        <Td>{user?.gender || ""}</Td>
        <Td>{user?.nationality?.name || ""}</Td>
        <Td>{business?.id ? "O" : "X"}</Td>
        <Td>{moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}</Td>
        <Td>
          <button onClick={toggleViewMore}>
            {!viewMore ? "보기" : "닫기"}
          </button>
        </Td>
      </tr>
      {viewMore && (
        <tr>
          <Td colSpan={11}>
            <DetailWrapper>
              <DetailSectionTitle>기본정보</DetailSectionTitle>

              <Grid>
                <div>
                  <DetailFieldTitle>소개</DetailFieldTitle>
                  <Introduction disabled defaultValue={introduction || ""} />
                </div>

                <div>
                  <DetailFieldTitle>주민번호</DetailFieldTitle>
                  <div>{idNumber || ""}</div>
                </div>

                <div>
                  <DetailFieldTitle>승인여부</DetailFieldTitle>
                  <button
                    disabled={loading}
                    onClick={() => onVerifyClick(id, verified)}
                  >
                    {loading
                      ? "수정 중"
                      : verified
                      ? "승인 취소하기"
                      : "승인하기"}
                  </button>
                </div>
              </Grid>

              {!!business?.id && (
                <>
                  <DetailSectionTitle>비즈니스 정보</DetailSectionTitle>
                  <Grid>
                    <div>
                      <DetailFieldTitle>상호</DetailFieldTitle>
                      <div>{business?.name}</div>
                    </div>
                    <div>
                      <DetailFieldTitle>대표자명</DetailFieldTitle>
                      <div>{business?.ownerName}</div>
                    </div>
                    <div>
                      <DetailFieldTitle>사업자등록번호</DetailFieldTitle>
                      <div>{business?.registrationNumber}</div>
                    </div>
                    <div>
                      <DetailFieldTitle>세금계산서 이메일</DetailFieldTitle>
                      <div>{business?.taxEmail}</div>
                    </div>
                    <div>
                      <DetailFieldTitle>사업자등록증</DetailFieldTitle>
                      <div>
                        {business?.registrationMedia ? (
                          <Paper src={business?.registrationMedia?.uri} />
                        ) : (
                          <div>등록사항없음</div>
                        )}
                      </div>
                    </div>
                  </Grid>
                </>
              )}

              <DetailSectionTitle>정산 정보</DetailSectionTitle>
              <Grid>
                <div>
                  <DetailFieldTitle>은행명</DetailFieldTitle>
                  <div>{bankName}</div>
                </div>
                <div>
                  <DetailFieldTitle>계좌번호</DetailFieldTitle>
                  <div>{bankAccount}</div>
                </div>
                <div>
                  <DetailFieldTitle>통장사본</DetailFieldTitle>
                  <div>
                    {bankAccountMedia ? (
                      <Paper src={bankAccountMedia?.uri} />
                    ) : (
                      <div>등록사항없음</div>
                    )}
                  </div>
                </div>
              </Grid>
            </DetailWrapper>
          </Td>
        </tr>
      )}
    </>
  );
}

const DetailWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.normal,
}));

const DetailSectionTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    ...theme.font.body1,
    fontWeight: "bold",
    marginBottom: theme.spacing.small,
  })
);

const DetailFieldTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body2,
  fontWeight: "bold",
  marginBottom: theme.spacing.xxSmall2,
}));

const Introduction = styled.textarea<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: theme.spacing.normal,
    ...theme.font.body2,
    height: "fit-content",
    maxHeight: 400,
    width: "100%",
    resize: "none",
    color: theme.color.black,
    border: "none",

    ":disabled": {
      backgroundColor: theme.color.white,
    },
  })
);

const Grid = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "grid",
  gap: theme.spacing.small,
  gridTemplateColumns: "repeat(3,1fr)",
  gridAutoRows: "auto",
  marginBottom: theme.spacing.default,
}));

const Paper = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: "80%",
  height: "fit-content",
  objectFit: "contain",
}));

export default HostListRow;
