import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import Fields from "../components/Fields";
import Jobs from "../components/Jobs";
import Occupations from "../components/Occupation";
import Themes from "../components/Themes";

function OptionContainer() {
  return (
    <Wrapper>
      <Themes />
      <Fields />
      <Jobs />
      <Occupations />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "grid",
  padding: theme.spacing.default,
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "1fr 1fr",
  height: `calc(100vh - ${theme.topBarHeight}px)`,
  gap: theme.spacing.small,
}));

export default OptionContainer;
