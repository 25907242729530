import moment from "moment";
import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useEmailListContainer from "./EmailListContainer.hook";
import {
  Head,
  Row,
  Table,
  TBody,
  Td,
  Th,
} from "../../common/styles/commonStyles";

function EmailListContainer() {
  const {
    models: { loading, data },
    operations: { onCopyAllClick, onEmailClick },
  } = useEmailListContainer();

  if (loading) return <div />;

  return (
    <Wrapper>
      <ButtonsWrapper>
        <button onClick={onCopyAllClick}>모든 이메일 주소 복사하기</button>
      </ButtonsWrapper>
      <Table>
        <Head>
          <tr>
            <Th>ID</Th>
            <Th>Email</Th>
            <Th>Created At</Th>
          </tr>
        </Head>
        <TBody>
          {data?.map((emailList) => (
            <tr key={emailList?.id}>
              <Td>{emailList?.id}</Td>
              <Td
                style={{ width: "50%", cursor: "pointer" }}
                onClick={() => onEmailClick(emailList?.email!)}
              >
                {emailList?.email}
              </Td>
              <Td>{moment(emailList?.createdAt).format("YYYY-MM-DD")}</Td>
            </tr>
          ))}
        </TBody>
      </Table>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.default,
  position: "relative",
}));

const ButtonsWrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing.small,
  marginBottom: theme.spacing.default,
}));

export default EmailListContainer;
