import imageCompression from "browser-image-compression";
import React, { useEffect, useState } from "react";
import { uploadToFirebaseStorage } from "../../../lib/firebase";
import { COMPRESSION_OPTION } from "../../../utilites/constants";

type State = {
  id: string;
  name: string;
  iconState: any;
  iconUrl: string;
  order: number;
  phrase: string;
};

const defaultState: State = {
  id: "",
  name: "",
  iconState: null,
  iconUrl: "",
  order: 0,
  phrase: "",
};

function useOptionInput(
  value: {
    id?: string;
    name?: string;
    iconUrl?: string | null | undefined;
    order?: number;
    phrase?: string;
  } | null,
  onSubmit: ({
    id,
    name,
    iconUrl,
    order,
    phrase,
  }: {
    id: string | null;
    name: string;
    iconUrl: string;
    order: number;
    phrase: string;
  }) => void,
  onAdditionCancel?: () => void
) {
  const [editMode, setEditMode] = useState(false);

  const [state, setState] = useState<State>(defaultState);

  useEffect(() => {
    if (value) {
      setState({
        id: value.id!,
        name: value.name!,
        iconState: null,
        iconUrl: value.iconUrl || "",
        order: value.order!,
        phrase: value.phrase || "",
      });
    } else {
      setEditMode(true);
    }
  }, [value]);

  function onChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    if (files?.[0]) {
      setState((prev) => ({
        ...prev,
        iconState: files[0],
      }));
    }
  }

  async function onEditClick() {
    if (editMode) {
      const value = {
        id: state.id || null,
        name: state.name,
        iconUrl: state.iconUrl,
        order: Number(state.order) || 0,
        phrase: state.phrase,
      };

      if (state.iconState) {
        const compressedFile = await imageCompression(
          state.iconState,
          COMPRESSION_OPTION
        );
        const { name, size, type } = compressedFile;
        const metadata = { name, size, type };

        const url: string = await uploadToFirebaseStorage(
          compressedFile,
          metadata,
          "icons"
        );

        value.iconUrl = url;
      }

      onSubmit(value);
      setEditMode(false);
      onAdditionCancel && onAdditionCancel();
    } else {
      setEditMode(true);
    }
  }

  function onCancelClick() {
    if (state.id) {
      if (value) {
        setState({
          id: value.id!,
          name: value.name!,
          iconState: null,
          iconUrl: value.iconUrl || "",
          order: value.order!,
          phrase: value?.phrase || "",
        });
      }
      setEditMode(false);
    } else {
      onAdditionCancel && onAdditionCancel();
    }
  }

  return {
    models: {
      editMode,
      state,
    },
    operations: {
      onChange,
      onFileChange,
      onEditClick,
      onCancelClick,
    },
  };
}

export default useOptionInput;
